import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { PortfolioIcon } from '@copper/components';
import { selectCurrencies } from '@copper/entities/dictionaries/dictionaries-selector';
import { selectGroupedPortfolios, selectNestedPortfolios, selectPortfolios } from '@copper/entities/portfolios/portfolios-selector';
import { selectSearchPreferences } from '@copper/entities/revault/revault-selector';
import { selectWalletsByPortfolioId } from '@copper/entities/wallets/wallets-selector';
import { isTradingClearloopPortfolio } from '@copper/helpers/portfolios';
import { FormControl, FormLabel, Select, Tabs } from '@copper/ui-kit';
import { ShowUnavailableSwitch } from '../ShowUnavailableSwitch';
import { filterNestedUnavailable, getAvailableBalance } from '../TransferPortfolio/helpers';
import { filterUnavailable } from './helpers';
import s from './Portfolio.module.css';
export const PortfolioSelect = ({ children, name, label, labelTooltip, control, types, errorMessage, currencyId, mainCurrencyId, initialPortfolioId, testId, validateRules, hasUnavailableToggle = true, checkAvailableBalance = true, disabled, view = 'tool', excludePortfolioIds = [], indent = 'm' }) => {
    const portfolios = useSelector(selectPortfolios);
    const groupedPortfolios = useSelector(selectGroupedPortfolios);
    const nestedPortfolios = useSelector(selectNestedPortfolios);
    const { tokens, entities: currenciesEntities } = useSelector(selectCurrencies);
    const walletsByPortfolioId = useSelector(selectWalletsByPortfolioId);
    const searchPreferences = useSelector(selectSearchPreferences);
    const [showUnavailable, setShowUnavailable] = useState(false);
    const [activeTab, setActiveTab] = useState(types.includes('external') && types.length === 1 ? 'exchange' : 'internal');
    const { field: { onChange, value } } = useController({
        name,
        control: control,
        rules: validateRules
    });
    useEffect(() => {
        if (initialPortfolioId) {
            const selectedPortfolio = portfolios[initialPortfolioId];
            if (selectedPortfolio) {
                onChange(selectedPortfolio);
            }
        }
    }, [initialPortfolioId, onChange, portfolios]);
    useEffect(() => {
        if (!hasUnavailableToggle) {
            setShowUnavailable(true);
        }
    }, [hasUnavailableToggle]);
    const getItems = useCallback(() => {
        const internalGroups = [];
        const exchangeGroups = [];
        if (types.includes('trading-vault')) {
            const filteredVaultPortfolios = groupedPortfolios.vaultPortfolios.filter((portfolio) => portfolio.portfolioType === 'trading-vault' &&
                !excludePortfolioIds?.includes(portfolio.portfolioId));
            internalGroups.push({
                title: 'Vault',
                items: showUnavailable
                    ? filteredVaultPortfolios
                    : filterUnavailable({
                        portfolios: filteredVaultPortfolios,
                        walletsByPortfolioId,
                        currenciesEntities,
                        currencyId,
                        mainCurrencyId
                    })
            });
        }
        if (types.includes('oxygen-vault')) {
            const filteredOxygenPortfolios = groupedPortfolios.vaultPortfolios.filter((portfolio) => portfolio.portfolioType === 'oxygen-vault' &&
                !excludePortfolioIds?.includes(portfolio.portfolioId));
            internalGroups.push({
                title: 'Vault',
                items: showUnavailable
                    ? filteredOxygenPortfolios
                    : filterUnavailable({
                        portfolios: filteredOxygenPortfolios,
                        walletsByPortfolioId,
                        currenciesEntities,
                        currencyId,
                        mainCurrencyId
                    })
            });
        }
        if (types.includes('custody')) {
            const custodyPortfolios = groupedPortfolios.vaultPortfolios.filter((portfolio) => !excludePortfolioIds?.includes(portfolio.portfolioId));
            internalGroups.push({
                title: 'Vault',
                items: showUnavailable
                    ? custodyPortfolios
                    : filterUnavailable({
                        portfolios: custodyPortfolios,
                        walletsByPortfolioId,
                        currenciesEntities,
                        currencyId,
                        mainCurrencyId
                    })
            });
        }
        if (types.includes('trading')) {
            const tradingPortfolios = groupedPortfolios.tradePortfolios.filter((portfolio) => !excludePortfolioIds?.includes(portfolio.portfolioId));
            internalGroups.push({
                title: 'Trading',
                items: showUnavailable
                    ? tradingPortfolios
                    : filterUnavailable({
                        portfolios: tradingPortfolios,
                        walletsByPortfolioId,
                        currenciesEntities,
                        currencyId,
                        mainCurrencyId
                    })
            });
        }
        if (types.includes('trading-default')) {
            const defaultTradingPortfolios = groupedPortfolios.tradePortfolios.filter((portfolio) => !isTradingClearloopPortfolio(portfolio) &&
                !excludePortfolioIds?.includes(portfolio.portfolioId));
            internalGroups.push({
                title: 'Trading',
                items: showUnavailable
                    ? defaultTradingPortfolios
                    : filterUnavailable({
                        portfolios: defaultTradingPortfolios,
                        walletsByPortfolioId,
                        currenciesEntities,
                        currencyId,
                        mainCurrencyId
                    })
            });
        }
        if (types.includes('fees-vault')) {
            const feesVaultPortfolios = groupedPortfolios.feePortfolios.filter((portfolio) => !excludePortfolioIds?.includes(portfolio.portfolioId));
            internalGroups.push({
                title: 'Fee',
                items: showUnavailable
                    ? feesVaultPortfolios
                    : filterUnavailable({
                        portfolios: feesVaultPortfolios,
                        walletsByPortfolioId,
                        currenciesEntities,
                        currencyId,
                        mainCurrencyId
                    })
            });
        }
        if (types.includes('trading-clearloop')) {
            const clearloopTradingPortfolios = groupedPortfolios.tradePortfolios.filter((portfolio) => isTradingClearloopPortfolio(portfolio) &&
                !excludePortfolioIds?.includes(portfolio.portfolioId));
            internalGroups.push({
                title: 'Trading',
                items: showUnavailable
                    ? clearloopTradingPortfolios
                    : filterUnavailable({
                        portfolios: clearloopTradingPortfolios,
                        walletsByPortfolioId,
                        currenciesEntities,
                        currencyId,
                        mainCurrencyId
                    })
            });
        }
        if (types.includes('clearloop')) {
            const clearloopPortfolios = groupedPortfolios.clearloopPortfolios.filter((portfolio) => !excludePortfolioIds?.includes(portfolio.portfolioId));
            internalGroups.push({
                title: 'ClearLoop',
                items: showUnavailable
                    ? clearloopPortfolios
                    : filterUnavailable({
                        portfolios: clearloopPortfolios,
                        walletsByPortfolioId,
                        currenciesEntities,
                        currencyId,
                        mainCurrencyId,
                        checkAvailableBalance
                    })
            });
        }
        if (types.includes('csl-pledge')) {
            const cslPledgePortfolios = groupedPortfolios.cslPledgePortfolios.filter((portfolio) => !excludePortfolioIds?.includes(portfolio.portfolioId));
            internalGroups.push({
                title: 'Pledge',
                items: showUnavailable
                    ? cslPledgePortfolios
                    : filterUnavailable({
                        portfolios: cslPledgePortfolios,
                        currenciesEntities,
                        walletsByPortfolioId,
                        currencyId,
                        mainCurrencyId,
                        checkAvailableBalance
                    })
            });
        }
        if (types.includes('external')) {
            const externalPortfolios = nestedPortfolios.externalPortfolios.filter((portfolio) => !excludePortfolioIds?.includes(portfolio.item.portfolioId));
            exchangeGroups.push({
                title: 'Exchange',
                nestedItems: showUnavailable
                    ? externalPortfolios
                    : filterNestedUnavailable({
                        portfolios: externalPortfolios,
                        currenciesEntities,
                        walletsByPortfolioId,
                        currencyId,
                        mainCurrencyId
                    })
            });
        }
        if (activeTab === 'internal') {
            return internalGroups;
        }
        else if (activeTab === 'exchange') {
            return exchangeGroups;
        }
    }, [
        types,
        activeTab,
        groupedPortfolios.vaultPortfolios,
        groupedPortfolios.tradePortfolios,
        groupedPortfolios.clearloopPortfolios,
        showUnavailable,
        walletsByPortfolioId,
        currenciesEntities,
        currencyId,
        mainCurrencyId,
        nestedPortfolios.externalPortfolios
    ]);
    return (_jsxs(FormControl, { errorMessage: errorMessage, indent: indent, children: [_jsx(FormLabel, { tooltipInfo: labelTooltip, view: view, children: label }), _jsx(Select, { view: view, placeholder: "Select account", searchPlaceholder: "Search accounts", itemsGroups: getItems(), searchFields: ['portfolioName'], emptyDataString: "No account found", emptySearchString: "No account with this name found", getKey: (item) => item.portfolioId, getIcon: (portfolio) => _jsx(PortfolioIcon, { portfolio: portfolio }), getDisabledOption: (portfolio) => !!portfolio.isShadow, getTopRow: (portfolio) => portfolio.isShadow ? 'Restricted account' : portfolio.portfolioName, searchType: searchPreferences, getBottomRow: (item) => item.isShadow
                    ? '*****'
                    : getAvailableBalance({
                        walletsByPortfolioId,
                        currenciesEntities,
                        tokens,
                        portfolio: item,
                        currencyId,
                        mainCurrencyId
                    }), renderMidSection: () => (_jsxs(_Fragment, { children: [hasUnavailableToggle && (_jsx(ShowUnavailableSwitch, { label: "Show unavailable accounts", showUnavailable: showUnavailable, setShowUnavailable: setShowUnavailable })), types.includes('external') && types.length > 1 && (_jsx("div", { className: s.tabsContainer, children: _jsx(Tabs, { view: view, items: [
                                    {
                                        title: 'Copper',
                                        value: 'internal'
                                    },
                                    {
                                        title: 'Exchange',
                                        value: 'exchange'
                                    }
                                ].filter(Boolean), value: activeTab, onChange: (item) => setActiveTab(item.value) }) }))] })), onChange: onChange, paddingLeft: "l", selected: value, testId: testId, disabled: disabled }), children] }));
};
