import { createSelector } from '@reduxjs/toolkit';
import { selectOrganizations } from '../organizations/organizations-selector';
export const selectCounterpartiesState = (state) => state.counterparties;
export const selectCounterpartiesEntities = createSelector(selectCounterpartiesState, (counterparties) => counterparties.entities);
export const selectCounterparties = createSelector(selectCounterpartiesState, (counterparties) => Object.values(counterparties.entities));
export const selectCounterpartiesWithPendingRequest = createSelector(selectCounterparties, (counterparties) => counterparties.filter((counterparty) => counterparty._embedded?.counterpartyConnection?.requestStatus === 'pending'));
export const selectCounterpartiesWithConnection = createSelector(selectCounterparties, (counterparties) => counterparties.filter((counterparty) => ['accepted', 'pending'].includes(counterparty._embedded?.counterpartyConnection?.requestStatus ?? '')));
export const selectCounterpartiesEntitiesByOrgIds = createSelector(selectOrganizations, selectCounterpartiesEntities, (organizations, counterpartyEntities) => organizations.reduce((acc, organization) => {
    const { extra: { counterpartyId = '' } = {}, organizationId } = organization;
    const counterparty = counterpartyEntities[counterpartyId];
    if (!counterparty) {
        return acc;
    }
    return { ...acc, [organizationId]: counterparty };
}, {}));
//to be update using the counterpartyType in the counterparty card as operations will be deprecated
export const selectConnectedLoanBrokers = createSelector(selectCounterpartiesWithConnection, (counterparties) => counterparties.filter((counterparty) => counterparty.operations.includes('loan-broker') &&
    counterparty._embedded?.counterpartyConnection?.requestStatus === 'accepted'));
