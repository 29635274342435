import * as Yup from 'yup';
export const validationSchema = Yup.object().shape({
    portfolio: Yup.object().required('Select an account to fund the loan'),
    amount: Yup.string().required('Enter a loan amount.'),
    counterparty: Yup.object().required('Select a loan broker'),
    currency: Yup.object().required('Select an asset'),
    mainCurrency: Yup.string().required('Select a network'),
    openTerm: Yup.boolean(),
    maturityDate: Yup.string().when('openTerm', {
        is: (openTerm) => !openTerm,
        then: (schema) => schema.required('Select a maturity date')
    })
});
