import { createSelector } from '@reduxjs/toolkit';
import { emptyObject } from '@copper/utils/empty';
import { selectAdminUsers } from '@copper/entities/adminUsers/adminUsers-selector';
export const selectOrganizations = (store) => store.organizations?.organizations;
export const selectNonLiteOrganizations = createSelector(selectOrganizations, (organizations) => organizations.filter((organization) => organization?.organizationType !== 'lite'));
export const selectArchivedOrganizations = createSelector(selectOrganizations, (organizations) => organizations.filter((organization) => !organization?.isActive));
export const selectCurrentOrganization = (store) => store.organizations?.currentOrganization;
export const selectOrganizationFees = (store) => store.organizations?.organizationFees;
// We want to enable custom billing for all the environments excluding the production environment,
// to allow admin users for easy way to change values and do some testing in UI instead of Salesforce.
export const selectOrganizationCustomBilled = createSelector(selectOrganizationFees, (organizationFees) => window.envConfig?.disabledFeatures?.customBilled ? organizationFees?.customBilled : true);
export const selectOrganizationFeeSchedules = createSelector(selectOrganizationFees, (organizationFees) => organizationFees?.feeSchedules ?? {});
export const selectOrganizationInfo = (store) => store.organizations?.organizationInfo;
export const selectLastToSign = createSelector(selectCurrentOrganization, (currentOrganization) => currentOrganization?.extra?.lastToSign);
export const selectWalledGardenManager = createSelector(selectCurrentOrganization, (currentOrganization) => currentOrganization?.extra?.walledGardenManager);
export const selectOrganizationWhiteLock = createSelector(selectCurrentOrganization, (organization) => organization?.extra?.whiteLockEnabled == true);
export const selectIsAgencyOrganization = createSelector(selectCurrentOrganization, (organization) => organization?.extra?.organizationSubType === 'agency-desk');
export const selectIsAgencyLendingLenderOrganization = createSelector(selectCurrentOrganization, (organization) => organization?.extra?.organizationFeatures?.includes('agency-lending-principal-lender') ?? false);
export const selectIsAgencyLendingBorrowerOrganization = createSelector(selectCurrentOrganization, (organization) => organization?.extra?.organizationFeatures?.includes('agency-lending-borrower') ?? false);
export const selectIsBilateralBorrowerOrganization = createSelector(selectCurrentOrganization, (organization) => organization?.extra?.organizationFeatures?.includes('bilateral-borrower') ?? false);
export const selectIsBilateralLenderOrganization = createSelector(selectCurrentOrganization, (organization) => organization?.extra?.organizationFeatures?.includes('bilateral-lender') ?? false);
export const selectOrganizationMembers = createSelector(selectCurrentOrganization, (organization) => organization?.members ?? emptyObject());
export const selectOrganizationActiveMembersList = createSelector(selectOrganizationMembers, (members) => Object.values(members).filter((member) => !member?.isDeleted && member?.isActive && member?.user?.userType === 'user'));
export const selectOrganizationPendingMembersList = createSelector(selectOrganizationMembers, (members) => Object.values(members).filter((member) => !member?.isDeleted && !member?.isActive && member?.user?.userType === 'user'));
export const selectOrganizationDeactivatedMembersList = createSelector(selectOrganizationMembers, (members) => Object.values(members).filter((member) => member?.isDeleted && !member?.isActive && member?.user?.userType === 'user'));
export const selectOrganizationActiveServiceAccountsList = createSelector(selectOrganizationMembers, (members) => Object.values(members).filter((member) => !member?.isDeleted && member?.isActive && member?.user?.userType === 'service-user'));
export const selectOrganizationPendingServiceAccountsList = createSelector(selectOrganizationMembers, (members) => Object.values(members).filter((member) => !member?.isDeleted && !member?.isActive && member?.user?.userType === 'service-user'));
export const selectOrganizationDeactivatedServiceAccountsList = createSelector(selectOrganizationMembers, (members) => Object.values(members).filter((member) => member?.isDeleted && !member?.isActive && member?.user?.userType === 'service-user'));
export const selectCombinedUsers = createSelector(selectOrganizationMembers, selectAdminUsers, (organizationMembers, adminUsers) => ({
    ...adminUsers,
    ...Object.values(organizationMembers).reduce((acc, member) => {
        if (!member) {
            return acc;
        }
        return {
            ...acc,
            [member.userId]: member.user
        };
    }, {})
}));
export const selectOrganizationCounterparty = createSelector(selectCurrentOrganization, (organization) => organization?.counterparty);
export const selectOrganizationRestrictedResources = (state) => state.organizations.organizationRestrictedResources;
export const selectCustodyRestrictedResources = createSelector(selectOrganizationRestrictedResources, (restrictedResources) => restrictedResources.find(({ featureName }) => featureName === 'custody'));
export const selectTravelRuleVerificationRequired = createSelector(selectCurrentOrganization, (organization) => organization?.extra?.travelRuleVerificationRequired ?? false);
export const selectNonCustodialWalletsEnabled = createSelector(selectCurrentOrganization, (organization) => organization?.extra?.nonCustodialWalletsEnabled ?? false);
export const selectFirstLienSettings = createSelector(selectCurrentOrganization, (currentOrganization) => currentOrganization?.extra?.firstLienSettings);
export const selectLendingSettings = createSelector(selectCurrentOrganization, (currentOrganization) => currentOrganization?.extra?.lendingSettings);
