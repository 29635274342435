import { useCallback, useEffect, useState } from 'react';
import { getClearloopCurrencies } from '@copper/api/clearloop';
import { groupBy } from 'lodash-es';
export const useClearloopCurrencies = (organizationId, portfolio) => {
    const [currencies, setCurrencies] = useState();
    const fetchCurrencies = useCallback(async (exchangeId) => {
        try {
            const { data } = await getClearloopCurrencies({
                exchangeId,
                organizationId
            });
            setCurrencies(groupBy(data.clearLoopCurrencies.map((currency) => ({
                ...currency,
                decimals: parseInt(currency.decimals, 10)
            })), 'currency'));
        }
        catch (_) {
            setCurrencies(undefined);
        }
    }, [portfolio?.extra?.exchange, organizationId]);
    useEffect(() => {
        if (portfolio?.extra?.exchange) {
            fetchCurrencies(portfolio.extra.exchange);
            return;
        }
        setCurrencies(undefined);
    }, [portfolio?.extra?.exchange]);
    return { currencies };
};
