import { request } from '@copper/utils';
export const getAll = () => getByQuery({});
export const getByQuery = (params) => request.get(`/organizations`, {
    params
});
export const get = ({ organizationId }) => request.get(`/organizations/${organizationId}?includeApprovalRequests=true`);
export const getOrganizationDetails = ({ organizationId, ...params }) => request.get(`/organizations/${organizationId}`, { params });
export const getOrganizationInfo = (organizationId) => request.get(`/organizations/${organizationId}/info/legal`);
export const getMemberDetails = ({ organizationId, userId, ...params }) => request.get(`/organizations/${organizationId}/members/${userId}`, { params });
export const createOrganization = (data) => request.post('/organizations', data, {
    headers: {
        'Content-type': 'application/vnd.create-organization+json'
    }
});
export const addMember = (organizationId, memberData, twoFaCode) => request.post(`/organizations/${organizationId}/members`, memberData, {
    headers: {
        'Content-Type': 'application/vnd.add-organization-member+json',
        'X-2FA-Code': twoFaCode
    }
});
export const activateMember = (organizationId, userId, activationData) => request.patch(`/organizations/${organizationId}/members/${userId}`, activationData, {
    headers: {
        'Content-Type': 'application/vnd.activate-member+json'
    }
});
export const resendMemberActivationLink = (organizationId, userId) => request.patch(`/organizations/${organizationId}/members/${userId}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.resend-activation-link+json'
    }
});
export const deleteOrganizationMember = ({ organizationId, userId, twoFaCode, force }) => request.delete(`/organizations/${organizationId}/members/${userId}`, {
    data: {
        ...(force && { force: true })
    },
    headers: {
        'X-2FA-Code': twoFaCode
    }
});
export const updateMemberPermissionSet = ({ organizationId, userId, permissions, portfolioIds, twoFaCode }) => request.patch(`/organizations/${organizationId}/members/${userId}/permissions`, { userId, portfolioIds, permissions, organizationId }, {
    headers: {
        'Content-type': 'application/vnd.update-member-permission-set+json',
        'X-2FA-Code': twoFaCode
    }
});
export const addMemberPermissionSet = ({ organizationId, userId, permissions, portfolioIds, twoFaCode }) => request.post(`/organizations/${organizationId}/members/${userId}/permissions`, { userId, portfolioIds, permissions, organizationId }, {
    headers: {
        'Content-type': 'application/vnd.update-member-permission-set+json',
        'X-2FA-Code': twoFaCode
    }
});
export const updateMemberOrderLimits = ({ organizationId, userId, duration, byOrderAmounts, byTotalWithdrawals, portfolioIds, twoFaCode }) => request.patch(`/organizations/${organizationId}/members/${userId}/order-limits`, { userId, portfolioIds, duration, byOrderAmounts, byTotalWithdrawals, organizationId }, {
    headers: {
        'Content-type': 'application/vnd.update-member-order-limits-set+json',
        'X-2FA-Code': twoFaCode
    }
});
export const addMemberOrderLimits = ({ organizationId, userId, duration, byOrderAmounts, byTotalWithdrawals, portfolioIds, twoFaCode }) => request.post(`/organizations/${organizationId}/members/${userId}/order-limits`, { userId, portfolioIds, duration, byOrderAmounts, byTotalWithdrawals, organizationId }, {
    headers: {
        'Content-type': 'application/vnd.update-member-order-limits+json',
        'X-2FA-Code': twoFaCode
    }
});
export const deleteMemberPermissionSet = ({ organizationId, userId, permissionSet, twoFaCode }) => request.delete(`/organizations/${organizationId}/members/${userId}/permissions`, {
    data: permissionSet,
    headers: {
        'X-2FA-Code': twoFaCode
    }
});
export const deleteMemberOrderLimitSet = ({ organizationId, userId, orderLimitSet, twoFaCode }) => request.delete(`/organizations/${organizationId}/members/${userId}/order-limits`, {
    data: orderLimitSet,
    headers: {
        'X-2FA-Code': twoFaCode
    }
});
export const updateBankAccount = (data, bankAccountId, twoFaCode) => request.put(`/bank-accounts/${bankAccountId}`, data, {
    headers: {
        'X-2FA-Code': twoFaCode
    }
});
export const deleteBankAccount = (bankAccountId, twoFaCode) => request.delete(`/bank-accounts/${bankAccountId}`, {
    headers: {
        'X-2FA-Code': twoFaCode
    }
});
export const sendFiatDepositNotification = (organizationId, data) => request.post(`/organizations/${organizationId}/activities`, data, {
    headers: {
        'Content-type': 'application/vnd.create-add-funds-notification-activity+json'
    }
});
export const getFees = (organizationId) => request.get(`/organizations/${organizationId}/fees`);
export const getRestrictedResources = (organizationId) => request.get(`/organizations/${organizationId}/restricted-resources`);
export const updateLiteOrganizationInfo = (organizationId, data) => request.post(`/organizations/${organizationId}/counterparties`, data, {
    headers: {
        'Content-Type': 'application/vnd.lite-organization-member-signup+json'
    }
});
export const updateBlocked = (organizationId, data, twoFaCode) => request.patch(`/organizations/${organizationId}`, data, {
    headers: {
        'Content-type': 'application/vnd.update-organization-blocked+json',
        'X-2FA-Code': twoFaCode
    }
});
export const updateFees = (organizationId, data) => request.patch(`/organizations/${organizationId}/fees`, { fees: data }, {
    headers: {
        'Content-type': 'application/vnd.update-fee-settings+json'
    }
});
export const updateInfo = (organizationId, data) => request.patch(`/organizations/${organizationId}/info/legal`, data, {
    headers: {
        'Content-type': 'application/vnd.update-organization-legal-info+json'
    }
});
export const updateMembersExemptions = (organizationId, data, twoFaCode) => request.patch(`/organizations/${organizationId}/members`, data, {
    headers: {
        'Content-type': 'application/vnd.update-members-exemptions+json',
        'X-2FA-Code': twoFaCode
    }
});
export const updateSettings = (organizationId, data, twoFaCode) => request.patch(`/organizations/${organizationId}`, data, {
    headers: {
        'Content-type': 'application/vnd.update-organization-settings+json',
        'X-2FA-Code': twoFaCode
    }
});
export const setupMasterPassword = (organizationId, data) => request.patch(`/organizations/${organizationId}`, data, {
    headers: {
        'Content-type': 'application/vnd.setup-master-password+json'
    }
});
export const resetMasterPassword = (organizationId, twoFaCode) => request.patch(`/organizations/${organizationId}`, {}, {
    headers: {
        'X-2FA-Code': twoFaCode,
        'Content-type': 'application/vnd.reset-master-password+json'
    }
});
export const archiveOrganization = (organizationId) => request.patch(`/organizations/${organizationId}`, {}, {
    headers: {
        'Content-type': 'application/vnd.archive-organization+json'
    }
});
export const unarchiveOrganization = (organizationId) => request.patch(`/organizations/${organizationId}`, {}, {
    headers: {
        'Content-type': 'application/vnd.unarchive-organization+json'
    }
});
export const getApiKeys = (organizationId) => request.get(`/organizations/${organizationId}/api-keys`);
export const createApiKey = (organizationId, data, twoFaCode) => request.post(`/organizations/${organizationId}/api-keys`, data, {
    headers: {
        'Content-type': 'application/vnd.create-apikey+json',
        'X-2FA-Code': twoFaCode
    }
});
export const updateApiKey = (data, twoFaCode, organizationId, apiKey) => request.patch(`/organizations/${organizationId}/api-keys/${apiKey}`, data, {
    headers: {
        'X-2FA-Code': twoFaCode,
        'Content-Type': 'application/vnd.update-apikey+json'
    }
});
export const deleteApiKey = (organizationId, apiKey, twoFaCode) => request.delete(`/organizations/${organizationId}/api-keys/${apiKey}`, {
    data: {},
    headers: {
        ...(twoFaCode && { 'X-2FA-Code': twoFaCode }),
        'Content-type': 'application/vnd.delete-apikey+json'
    }
});
export const inviteLiteOrganizationMember = (data) => request.post('/organizations', data, {
    headers: {
        'Content-Type': 'application/vnd.invite-lite-organization-member+json'
    }
});
export const getBillingSubscriptions = (organizationId, params) => request.get(`/organizations/${organizationId}/billing/subscriptions`, { params });
export const createPayment = (organizationId, data) => request.post(`/organizations/${organizationId}/billing/payments`, data);
export const getPayment = (organizationId, paymentId, params) => request.get(`/organizations/${organizationId}/billing/payments/${paymentId}`, {
    params
});
export const changePayment = (organizationId, paymentId, data) => request.patch(`/organizations/${organizationId}/billing/payment-methods/${paymentId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.update-payment-method+json'
    }
});
export const cancelSubscription = (organizationId, subscriptionId, data) => request.patch(`/organizations/${organizationId}/billing/subscriptions/${subscriptionId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.cancel-subscription+json'
    }
});
export const dryRunSubscriptionChange = (organizationId, subscriptionId, data) => request.patch(`/organizations/${organizationId}/billing/subscriptions/${subscriptionId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.dry-run-subscription-change+json'
    }
});
export const changeSubscription = (organizationId, subscriptionId, data) => request.patch(`/organizations/${organizationId}/billing/subscriptions/${subscriptionId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.change-subscription+json'
    }
});
export const getControllersSets = (organizationId) => request.get(`/organizations/${organizationId}/controllers-sets`);
export const getControllersCreationSessions = (organizationId) => request.get(`/organizations/${organizationId}/controllers-sessions`);
export const createControllersSession = (organizationId, createControllersSession) => request.post(`/organizations/${organizationId}/controllers-sessions`, createControllersSession);
export const cancelControllersSession = (organizationId, sessionId) => request.patch(`/organizations/${organizationId}/controllers-sessions/${sessionId}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.cancel-controllers-set-creation+json'
    }
});
export const joinControllersCreation = (organizationId, sessionId, data) => request.patch(`/organizations/${organizationId}/controllers-sessions/${sessionId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.join-controllers-set-creation+json'
    }
});
export const updateLeverageRatioThreshold = (organizationId, data, twoFaCode) => request.patch(`/organizations/${organizationId}`, data, {
    headers: {
        'X-2FA-Code': twoFaCode,
        'Content-Type': 'application/vnd.update-first-lien-leverage-ratio-threshold+json'
    }
});
export const updateCollateralCurrencies = (organizationId, data, twoFaCode) => request.patch(`/organizations/${organizationId}`, data, {
    headers: {
        'X-2FA-Code': twoFaCode,
        'Content-Type': 'application/vnd.update-first-lien-eligible-collateral-currencies+json'
    }
});
export const updateAlertThresholdDeltas = (organizationId, data, twoFaCode) => request.patch(`/organizations/${organizationId}`, data, {
    headers: {
        'X-2FA-Code': twoFaCode,
        'Content-Type': 'application/vnd.update-lending-alert-threshold-deltas+json'
    }
});
export const createBoundedTokenForOrganization = (organizationId) => request.post(`/organizations/${organizationId}/bounded-tokens`);
