import { NOT_ACTIVATED } from '@copper/components/Forms/TransferPortfolio/constants';
import { getCurrencyDecimal } from '@copper/helpers';
import { formatCurrency } from '@copper/utils';
export const getPortfolioBalanceInNetwork = (currency, mainCurrency, walletsInCurrency, tokens) => {
    const wallet = walletsInCurrency.find((wallet) => wallet.currency === currency && wallet.mainCurrency === mainCurrency);
    if (!wallet) {
        return NOT_ACTIVATED;
    }
    return `${formatCurrency(wallet.available, {
        decimalPlaces: getCurrencyDecimal({
            tokens,
            currencyId: currency,
            mainCurrencyId: mainCurrency
        })
    })} ${currency}${currency !== mainCurrency ? `-${mainCurrency}` : ''}`;
};
