import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { AssetIcon, InfoAccount, InfoRow } from '@copper/components';
import { SwitchStateRow } from '@copper/components/Forms/SwitchStateRow';
import { selectCurrencies } from '@copper/entities/dictionaries/dictionaries-selector';
import { selectPortfolios } from '@copper/entities/portfolios/portfolios-selector';
import { getCurrencyName } from '@copper/helpers';
import { useCurrenciesInfo } from '@copper/hooks/useCurrenciesInfo';
import { formatCurrency } from '@copper/utils';
import s from './Summary.module.css';
export const Summary = ({ isSubmitting, data, onNext }) => {
    const { getDecimals } = useCurrenciesInfo();
    const { entities: currenciesEntities } = useSelector(selectCurrencies);
    const groupedPortfolios = useSelector(selectPortfolios);
    const { clearLoopType, currency, origin, amount, description } = data;
    const parentPortfolioId = origin?.extra?.parentPortfolioId;
    const parentPortfolio = groupedPortfolios[parentPortfolioId ?? ''];
    if (!currency || !origin || !amount) {
        return null;
    }
    const currencyId = currency.currency;
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: s.container, children: [_jsx("h2", { className: s.title, children: "Summary" }), _jsx(InfoRow, { title: clearLoopType === 'lock' ? 'You delegate' : 'You undelegate', titleColor: "ghost", children: _jsxs("div", { className: s.asset, children: [getCurrencyName(currencyId, currenciesEntities), _jsx("span", { className: s.ticker, children: currencyId }), _jsx(AssetIcon, { size: "s", currency: currencyId })] }) }), _jsx(InfoRow, { title: clearLoopType === 'lock' ? 'To' : 'From', titleColor: "ghost", children: _jsx(InfoAccount, { account: origin }) }), parentPortfolio && (_jsx(InfoRow, { title: clearLoopType === 'lock' ? 'From' : 'To', titleColor: "ghost", children: _jsx(InfoAccount, { account: parentPortfolio }) })), description && (_jsx(InfoRow, { title: "Optional note", titleColor: "ghost", children: description })), _jsx(InfoRow, { title: "Amount", titleColor: "ghost", children: _jsxs("span", { className: s.total, children: [formatCurrency(amount, {
                                    decimalPlaces: getDecimals(currencyId, 'USD', {
                                        useDictionaryDecimal: true
                                    })
                                }), ' ', currencyId] }) })] }), _jsx(SwitchStateRow, { loading: isSubmitting, onClick: onNext, text: "Confirm", testId: "clearloop-button-confirm" })] }));
};
