import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert } from '@copper/ui-kit';
import s from '../Assets.module.css';
export const HBAR = {
    title: 'HBAR',
    id: 'hbar',
    qa: [
        {
            question: 'How do I stake HBAR?',
            answer: (_jsxs(_Fragment, { children: [_jsxs("ol", { className: s.ol, children: [_jsx("li", { children: "Select an account to stake from." }), _jsx(Alert, { message: "By staking HBAR, you commit the full balance in your selected wallet. There is no need to choose a staking amount. Note that your assets will remain liquid at all times once staked.", type: "info" }), _jsxs("li", { children: ["Choose a validator node and enter the node number provided on", ' ', _jsx("a", { href: "https://hashscan.io/mainnet/nodes", rel: "noreferrer", target: "_blank", children: "HashScan" }), ' ', "in the validator address field."] }), _jsx(Alert, { message: "The node number (for example, 14) should not be confused with the node's account number, which follows the format 0.0.10.", type: "info" }), _jsx("li", { children: "Review and sign using Copper Unlimited." })] }), _jsx("p", { children: "Once broadcast, your stake will appear in the Active tab." })] }))
        },
        {
            question: 'How do I unstake HBAR?',
            answer: (_jsx("p", { children: "There is no option to unstake HBAR on the platform because your staked balance remains liquid at all times. This means that you are free to use your HBAR while your wallet balance remains staked to your designated node. Plus, you have the flexibility to change this validator node at any point in your staking details." }))
        },
        {
            question: 'Is there a minimum or maximum amount of HBAR I can stake?',
            answer: (_jsxs("p", { children: ["No, there is no minimum or maximum staking amount. However, your selected validator node must meet the minimum threshold amount to allow you to earn rewards. This minimum amount per node is variable. For more information, please visit", ' ', _jsx("a", { href: "https://hashscan.io/mainnet/nodes", rel: "noreferrer", target: "_blank", children: "HashScan" }), "."] }))
        },
        {
            question: 'How often will I earn HBAR staking rewards?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { children: "You will start earning rewards after your account has been staked for one day (24 hours) and will continue to earn rewards every 24-hour staking period, starting at midnight (00:00 UTC)." }), _jsx("table", { className: s.table, children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "First payout" }) }), _jsx("td", { className: s.td, children: "After 24 hours" })] }), _jsxs("tr", { children: [_jsx("td", { className: s.td, children: _jsx("b", { children: "Payout frequency" }) }), _jsx("td", { className: s.td, children: "Every 24 hours" })] })] }) })] }))
        },
        {
            question: 'How can I claim my HBAR staking rewards?',
            answer: (_jsxs(_Fragment, { children: [_jsx("p", { className: s.spaceAfter, children: "To claim your rewards, you can take either one of the following actions:" }), _jsxs("ul", { className: s.ul, children: [_jsx("li", { children: "Make a transfer to or from your staking vault" }), _jsx("li", { children: "Change validators (or nodes)" })] }), _jsx("p", { className: s.spaceAfter, children: "Either action will trigger the payment of your accumulated rewards directly to your staking vault." }), _jsx(Alert, { message: "There is a 365-day limit on how far back you can collect rewards without changing validators or transferring funds. If more than 365 days pass without a rewards payment, you will only be able to claim rewards for the most recent 365-day period.", type: "info" })] }))
        },
        {
            question: 'Can I change validators while my assets are staked?',
            answer: (_jsx("p", { children: "Yes. To change validators (or nodes), simply click on the active position on your staking dashboard. Then, select Change next to the validator's information." }))
        }
    ]
};
