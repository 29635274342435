import { createSelector } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import { mergeWith } from 'lodash-es';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { selectWalletsByPortfolioId } from '@copper/entities/wallets/wallets-selector';
import { createPortfolioList } from '@copper/helpers/nestedPortfolios';
import { isClearloopPortfolio, isClearloopSubAccount, isCslPledgePortfolio, isExternalCustodianClearloop } from '@copper/helpers/portfolios';
export const selectPortfolioEntities = (store) => store.portfolios.portfolios;
export const selectArchivedPortfolioEntities = (store) => store.portfolios.archivedPortfolios;
export const selectShadowPortfolios = (store) => store.portfolios.shadowPortfolios;
export const selectAllPortfolioEntities = createSelector(selectArchivedPortfolioEntities, selectPortfolioEntities, (archivedPortfolioEntities, portfolioEntities) => ({
    ...archivedPortfolioEntities,
    ...portfolioEntities
}));
export const selectPortfolios = createSelector(selectPortfolioEntities, selectWalletsByPortfolioId, (portfolioEntities, walletsByPortfolioId) => {
    const result = {};
    Object.keys(portfolioEntities)
        .filter((portfolioId) => portfolioEntities[portfolioId]?.isActive)
        .forEach((portfolioId) => {
        const portfolioWallets = walletsByPortfolioId[portfolioId] ?? [];
        const balances = portfolioWallets.reduce((acc, wallet) => {
            if (!Number(wallet.totalBalanceReporting) && !Number(wallet.availableReporting)) {
                return acc;
            }
            return {
                balance: acc.balance.plus(wallet.totalBalanceReporting), // balance + stakeBalance
                available: acc.available.plus(wallet.availableReporting),
                positiveAvailable: acc.positiveAvailable.plus(BigNumber.maximum(wallet.availableReporting, '0')),
                reserve: acc.reserve.plus(wallet.reserveReporting),
                stakeBalance: acc.stakeBalance.plus(wallet.stakeBalanceReporting)
            };
        }, {
            balance: new BigNumber('0'),
            available: new BigNumber('0'),
            positiveAvailable: new BigNumber('0'),
            reserve: new BigNumber('0'),
            stakeBalance: new BigNumber('0')
        });
        result[portfolioId] = {
            ...portfolioEntities[portfolioId],
            positiveAvailable: balances.positiveAvailable.toFixed(),
            balance: balances.balance.toFixed(),
            available: balances.available.toFixed(),
            reserve: balances.reserve.toFixed(),
            stakeBalance: balances.stakeBalance.toFixed()
        };
    });
    return result;
});
export const selectPortfolioList = createSelector(selectPortfolios, (portfolios) => Object.values(portfolios));
export const selectPortfolioIds = createSelector(selectAllPortfolioEntities, (portfolioEntities) => Object.keys(portfolioEntities));
export const selectGroupedPortfolios = createSelector(selectPortfolios, (portfolioEntities) => {
    const result = groupByPortfolioType(portfolioEntities);
    result.externalPortfolios = result.externalPortfolios.sort((a, b) => {
        if (a.extra?.mainClearLoop) {
            return -1; // main account should be first for CL sub-accounts
        }
        return a.portfolioName.toLowerCase().localeCompare(b.portfolioName.toLowerCase());
    });
    return result;
});
export const selectNestedPortfolios = createSelector(selectGroupedPortfolios, selectShadowPortfolios, selectCurrentOrganization, (groupedPortfolios, shadowPortfolios, currentOrganization) => {
    const groupedShadowPortfolios = groupByPortfolioType(shadowPortfolios);
    const externalCustodian = currentOrganization.extra?.externalCustodian;
    mergeWith(groupedShadowPortfolios, groupedPortfolios, (obj, src) => obj.concat(src));
    const { externalPortfolios, clearloopPortfolios, vaultPortfolios, tradePortfolios, feePortfolios, thirdParty, multiCustodySettlement, cslPledgePortfolios } = groupedShadowPortfolios;
    const childrenWGPortfolios = externalPortfolios.filter((portfolio) => portfolio.extra?.parentPortfolioId);
    const childrenPortfolios = [
        ...childrenWGPortfolios,
        ...clearloopPortfolios,
        ...cslPledgePortfolios
    ];
    return {
        vaultPortfolios: createPortfolioList(externalCustodian ? vaultPortfolios : vaultPortfolios.concat(childrenPortfolios)),
        tradePortfolios: createPortfolioList(externalCustodian ? tradePortfolios : tradePortfolios.concat(childrenPortfolios)),
        externalPortfolios: createPortfolioList(externalPortfolios),
        feePortfolios: createPortfolioList(feePortfolios),
        clearloopPortfolios: externalCustodian
            ? createPortfolioList(clearloopPortfolios.concat(childrenWGPortfolios))
            : [],
        thirdParty: createPortfolioList(thirdParty),
        multiCustodySettlement: createPortfolioList(multiCustodySettlement.concat(childrenPortfolios))
    };
});
export const selectTotalBalance = createSelector(selectPortfolios, (portfolios) => Object.keys(portfolios).reduce((acc, portfolioId) => {
    const portfolio = portfolios[portfolioId];
    if (
    // Exclude all portfolios with type === 'clearloop' and all CL sub-accounts
    (!isClearloopPortfolio(portfolio?.portfolioType) &&
        !isClearloopSubAccount(portfolio) &&
        !isCslPledgePortfolio(portfolio?.portfolioType)) ||
        isExternalCustodianClearloop(portfolio)) {
        acc.positiveAvailable = BigNumber.maximum(portfolio.positiveAvailable, '0')
            .plus(new BigNumber(acc.positiveAvailable))
            .toFixed();
        acc.balance = new BigNumber(acc.balance).plus(portfolio.balance).toFixed();
        acc.available = new BigNumber(acc.available).plus(portfolio.available).toFixed();
        acc.reserve = new BigNumber(acc.reserve).plus(portfolio.reserve).toFixed();
        acc.stakeBalance = new BigNumber(acc.stakeBalance).plus(portfolio.stakeBalance).toFixed();
        acc.pledged = new BigNumber(acc.pledged).plus(portfolio.pledged).toFixed();
    }
    return acc;
}, {
    positiveAvailable: '0',
    balance: '0',
    available: '0',
    reserve: '0',
    stakeBalance: '0',
    pledged: '0'
}));
export const groupByPortfolioType = (portfolioEntities) => {
    const result = {
        vaultPortfolios: [],
        externalPortfolios: [],
        tradePortfolios: [],
        clearloopPortfolios: [],
        feePortfolios: [],
        thirdParty: [],
        multiCustodySettlement: [],
        cslPledgePortfolios: []
    };
    Object.keys(portfolioEntities).forEach((portfolioId) => {
        const portfolio = portfolioEntities[portfolioId];
        if (portfolio && portfolio.isActive) {
            switch (portfolio.portfolioType) {
                case 'custody':
                case 'oxygen-vault':
                case 'trading-vault': {
                    result.vaultPortfolios.push(portfolio);
                    break;
                }
                case 'fees-vault': {
                    result.feePortfolios.push(portfolio);
                    break;
                }
                case 'trading': {
                    result.tradePortfolios.push(portfolio);
                    break;
                }
                case 'external': {
                    result.externalPortfolios.push(portfolio);
                    break;
                }
                case 'clearloop': {
                    result.clearloopPortfolios.push(portfolio);
                    break;
                }
                case 'external-wallets': {
                    result.thirdParty.push(portfolio);
                    break;
                }
                case 'multi-custody-settlement': {
                    result.multiCustodySettlement.push(portfolio);
                    break;
                }
                case 'csl-pledge': {
                    result.cslPledgePortfolios.push(portfolio);
                    break;
                }
            }
        }
    });
    return result;
};
export const selectCollateralPortfolioIds = createSelector(selectGroupedPortfolios, (groupedPortfolios) => [...groupedPortfolios.tradePortfolios, ...groupedPortfolios.vaultPortfolios].reduce((collateralPortfolioIds, portfolio) => {
    if (portfolio?.extra?.isCollateralPortfolio) {
        collateralPortfolioIds.push(portfolio?.portfolioId);
    }
    return collateralPortfolioIds;
}, []));
