import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getApprovalRequests } from '@copper/api/approvalRequests';
import { useCheckPermissionsContext } from '@copper/contexts/checkPermissions';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { selectUserId } from '@copper/entities/user/user-selector';
import { useSnackBar } from '@copper/ui-kit';
import { getErrorData } from '@copper/utils';
export const useAddressBookContext = () => useContext(Context);
const defaultContext = {
    view: 'client',
    isLiteOrg: false,
    canUserUpdateAddress: () => false,
    fetchApprovalRequests: () => null,
    getBaseUrl: () => ''
};
export const Context = createContext(defaultContext);
export const AddressBookContextProvider = ({ children, view }) => {
    const [approvalRequests, setApprovalRequests] = useState([]);
    const { checkPermissions } = useCheckPermissionsContext();
    const userId = useSelector(selectUserId);
    const { organizationType } = useSelector(selectCurrentOrganization);
    const { organizationId } = useSelector(selectCurrentOrganization);
    const { openSnackbar } = useSnackBar();
    const canUserUpdateAddress = useCallback((address) => {
        const associatedRequest = approvalRequests.find((request) => request.associatedResourceId === address.cryptoAddressId);
        let actionNeeded = 'review-crypto-address';
        if (associatedRequest?.stage === '1')
            actionNeeded = 'approve-update-crypto-address-account-controller';
        if (associatedRequest?.stage === '2') {
            const previousApproval = associatedRequest?._embedded?.approvalAudits?.[0];
            if (previousApproval?.reviewerId === userId)
                return false;
            actionNeeded = 'approve-update-crypto-address-authorised-signatory';
        }
        return ((view === 'client' &&
            checkPermissions([actionNeeded]) &&
            address.createdBy !== userId &&
            address.updatedBy !== userId) ||
            (view === 'admin' && organizationType === 'lite'));
    }, [approvalRequests, checkPermissions, view, userId, organizationType]);
    const fetchApprovalRequests = async () => {
        try {
            const params = {
                userId,
                organizationId,
                actionName: 'update-crypto-address',
                requestStatus: 'waiting-approval',
                includeApprovalAudits: true,
                includePolicyStages: true
            };
            const res = await getApprovalRequests(params);
            setApprovalRequests(res.data.requests);
        }
        catch (err) {
            openSnackbar(getErrorData(err));
        }
    };
    useEffect(() => {
        if (organizationId) {
            fetchApprovalRequests();
        }
    }, [organizationId]);
    const getBaseUrl = (organizationId) => {
        if (view === 'admin' && organizationId)
            return `/admin/organizations/${organizationId}/address-book`;
        return '/settings/address-book';
    };
    return (_jsx(Context.Provider, { value: {
            view,
            isLiteOrg: organizationType === 'lite',
            canUserUpdateAddress,
            fetchApprovalRequests,
            getBaseUrl
        }, children: children }));
};
