import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormLabel, Select } from '@copper/ui-kit';
import { addYears, format, setYear, subYears } from 'date-fns';
import { useMemo } from 'react';
import { useDayPicker } from 'react-day-picker';
import s from '../DatePicker.module.css';
export const YearsDropdown = ({ captionRef, view }) => {
    const { dayPickerProps, goToMonth, months } = useDayPicker();
    const fromMonth = dayPickerProps.startMonth || subYears(new Date(), 3);
    const toMonth = dayPickerProps.endMonth || addYears(new Date(), 10);
    const dropdownYears = [];
    const fromFullYear = fromMonth?.getFullYear();
    const toFullYear = toMonth?.getFullYear();
    for (let year = fromFullYear; year <= toFullYear; year++) {
        dropdownYears.push(setYear(new Date(), year));
    }
    const selectedToDisplay = useMemo(() => months[0].date, []);
    const handleChangeYear = (value) => {
        if (value != null) {
            const newDate = new Date(months[0].date.setFullYear(value.getFullYear()));
            goToMonth(newDate);
        }
    };
    return (_jsxs("div", { className: s.dropwdownContainer, children: [_jsx(FormLabel, { children: "Years" }), _jsx(Select, { dropwdownRef: captionRef, size: "m", items: dropdownYears, onChange: handleChangeYear, selected: selectedToDisplay, getKey: (date) => date.getFullYear().toString(), getBottomRow: (date) => format(date, 'yyyy'), view: view })] }));
};
