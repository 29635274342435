import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { AssetInfo, InfoAccount, InfoRow } from '@copper/components';
import { SwitchStateRow } from '@copper/components/Forms/SwitchStateRow';
import { IconLogo } from '@copper/ui-kit';
import { truncateStr } from '@copper/utils';
import figment from '../icons/figment.svg';
import marinade from '../icons/marinade.svg';
import s from './Summary.module.css';
export const Summary = ({ data, isSubmitting, onNext }) => {
    const { amount, currency, portfolio, poolId, poolOperation, predefinedPool, drep } = data;
    if (!currency || !portfolio)
        return null;
    return (_jsxs("div", { className: s.container, children: [_jsxs("div", { className: s.wrapper, children: [_jsx("h2", { className: s.title, children: "Summary" }), _jsx(InfoRow, { title: "Asset", children: _jsx(AssetInfo, { align: "right", currency: currency.currency, iconSize: "m" }) }), _jsx(InfoRow, { title: "Account", children: _jsx(InfoAccount, { account: portfolio }) }), poolOperation === 'selectPredefined' && predefinedPool && (_jsx(InfoRow, { title: "Validator", children: _jsxs("div", { className: s.pool, children: [_jsxs("div", { children: [_jsx("div", { className: s.poolName, children: predefinedPool.poolName }), _jsx("div", { className: s.poolId, children: truncateStr(predefinedPool.poolId) })] }), predefinedPool?.poolName?.includes('Copper') && (_jsx(IconLogo, { width: 24, height: 24, viewBox: "0 0 32 32" }))] }) })), poolOperation === 'connect' && poolId && (_jsx(InfoRow, { title: "Validator", children: _jsx("div", { className: s.pool, children: poolId }) })), drep && (_jsx(InfoRow, { title: "DRep (Delegated Representative)", children: _jsx("div", { className: s.pool, children: drep }) })), poolOperation === 'figment' && (_jsx(InfoRow, { title: "Validator", children: _jsxs("div", { className: s.pool, children: [_jsx("div", { className: s.poolName, children: "Figment" }), _jsx("img", { src: figment, alt: "Figment", height: 24, width: 24 })] }) })), poolOperation === 'marinade' && (_jsx(InfoRow, { title: "Validator", children: _jsxs("div", { className: s.pool, children: [_jsx("div", { className: s.poolName, children: "Marinade Native" }), _jsx("img", { src: marinade, alt: "Marinade Native", height: 24, width: 24 })] }) })), amount && (_jsx(InfoRow, { title: "Amount", children: _jsxs("div", { className: s.total, children: [amount, " ", currency.currency, _jsx("div", { className: s.info, children: "Fee is not included" })] }) }))] }), _jsx(SwitchStateRow, { loading: isSubmitting, onClick: onNext, text: "Confirm" })] }));
};
