import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BANK_ROWS_MAP } from '@copper/helpers/bankAccounts';
import { ButtonIcon, ButtonLink, IconClipboard, useSnackBar } from '@copper/ui-kit';
import { copy } from '@copper/utils';
import s from './BankAccount.module.css';
export const BankAccount = ({ bankAccount, excludedFields }) => {
    const { openSnackbar } = useSnackBar();
    const filteredBankAccountFields = Array.from(BANK_ROWS_MAP.entries()).filter(([key]) => !excludedFields?.includes(key) && key in bankAccount);
    const handleCopyAll = () => {
        const data = filteredBankAccountFields
            .map(([key, title]) => `${title}: ${bankAccount[key]}`)
            .join('\n');
        copy(data, () => openSnackbar({ type: 'success', message: 'Copied' }));
    };
    return (_jsxs("div", { className: s.container, children: [_jsxs("div", { className: s.header, children: [_jsx("div", { className: s.title, children: "Deposit details" }), _jsx(ButtonLink, { strong: true, size: "s", text: "Copy all details", onClick: handleCopyAll })] }), filteredBankAccountFields.map(([key, title]) => {
                return (_jsxs("div", { className: s.item, children: [_jsx("div", { className: s.name, children: title }), _jsxs("div", { className: s.value, children: [bankAccount[key], _jsx(ButtonIcon, { onClick: () => copy(title, () => openSnackbar({ type: 'success', message: 'Copied' })), tooltipLabel: "Copy to clipboard", children: _jsx(IconClipboard, {}) })] })] }, `bank_acc_field_${key}`));
            })] }));
};
