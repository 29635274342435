import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import cuid from 'cuid';
import useStateMachine from '@cassiozen/usestatemachine';
import { useToolbar } from '@copper/components/Toolbar/ToolbarContext';
import { createOrder } from '@copper/entities/orders/orders-reducer';
import { getErrorData } from '@copper/utils';
import { DelegationForm } from './Form';
import { Result } from './Result';
import { Summary } from './Summary';
import { validationSchema } from './helpers';
import { selectUser } from '@copper/entities/user/user-selector';
import s from './OxygenDelegation.module.css';
export const OxygenDelegation = () => {
    const { setTopPanel } = useToolbar();
    const dispatch = useDispatch();
    const { isAdmin } = useSelector(selectUser);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [transactionErrorMessage, setTransactionErrorMessage] = useState();
    const [state, send] = useStateMachine({
        initial: 'form',
        states: {
            form: {
                on: { NEXT_STEP: 'summary' }
            },
            summary: {
                on: { BACK_STEP: 'form', NEXT_STEP: 'result' }
            },
            result: {
                on: { RESET: 'form' }
            }
        }
    });
    const form = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            type: isAdmin ? 'undelegate' : 'delegate'
        }
    });
    const { getValues } = form;
    const { currency, amount, type, account } = getValues();
    useEffect(() => {
        setTopPanel({
            title: type === 'delegate' ? 'Pledge' : 'Unpledge',
            onBack: state.value === 'summary' ? () => send({ type: 'BACK_STEP' }) : undefined
        });
    }, [state, send, setTopPanel]);
    const handleFormSubmit = () => {
        send({ type: 'NEXT_STEP' });
    };
    const handleFormSend = async () => {
        if (!currency || !amount) {
            return;
        }
        const data = {
            externalOrderId: cuid(),
            amount,
            baseCurrency: currency?.currency,
            orderType: type === 'delegate' ? 'oxygen-delegate' : 'oxygen-undelegate',
            portfolioId: account?.extra?.parentPortfolioId ?? '',
            toPortfolioId: type === 'delegate' ? account?.portfolioId : undefined,
            fromPortfolioId: type === 'undelegate' ? account?.portfolioId : undefined
        };
        setIsSubmitting(true);
        setTransactionErrorMessage(undefined);
        try {
            await dispatch(createOrder(data)).unwrap();
        }
        catch (err) {
            setTransactionErrorMessage(getErrorData(err).message);
        }
        finally {
            setIsSubmitting(false);
            send({ type: 'NEXT_STEP' });
        }
    };
    const handleDone = () => {
        form.reset();
        send('RESET');
    };
    return (_jsxs("div", { className: s.container, children: [state.value === 'form' && form && _jsx(DelegationForm, { onSubmit: handleFormSubmit, form: form }), state.value === 'summary' && (_jsx(Summary, { isSubmitting: isSubmitting, data: { ...getValues() }, onNext: handleFormSend })), state.value === 'result' && (_jsx(Result, { formData: { ...getValues() }, errorMessage: transactionErrorMessage, onDone: handleDone }))] }));
};
