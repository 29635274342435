import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { addDays, addSeconds, differenceInDays, differenceInSeconds, endOfDay, formatDuration, intervalToDuration } from 'date-fns';
import { InfoList, InfoRow } from '@copper/components';
import { SingleDatePicker } from '@copper/components/SingleDatePicker';
import { selectCurrencies } from '@copper/entities/dictionaries/dictionaries-selector';
import { FormControl } from '@copper/ui-kit';
import React from 'react';
import s from './DateEnd.module.css';
export const DateEndFields = ({ currency }) => {
    const { tokens } = useSelector(selectCurrencies);
    const { watch, formState: { errors }, control } = useFormContext();
    if (!currency) {
        return null;
    }
    const currencyData = tokens[`${currency.currency}-${currency.mainCurrency}`];
    const { endTime } = watch();
    const minStakeUnboundPeriod = currencyData?.extra?.minStakeUnboundPeriod;
    const maxStakeUnboundPeriod = currencyData?.extra?.maxStakeUnboundPeriod;
    const minDelegatorDuration = currencyData?.extra?.minDelegatorDuration
        ? Number(currencyData?.extra?.minDelegatorDuration) +
            differenceInSeconds(endOfDay(new Date()), new Date()) +
            1
        : null;
    const minStakeDate = addSeconds(new Date(), Number(minStakeUnboundPeriod ?? minDelegatorDuration ?? 0));
    const maxStakeDate = addSeconds(new Date(), Number(maxStakeUnboundPeriod ?? 0));
    const fromDate = differenceInDays(minStakeDate, new Date()) > 0 ? minStakeDate : addDays(new Date(), 1);
    return (_jsxs("div", { className: s.container, children: [_jsx("div", { className: s.duration, children: _jsx(FormControl, { errorMessage: errors.endTime?.message, children: _jsx(Controller, { control: control, name: "endTime", render: ({ field }) => (_jsx(SingleDatePicker, { view: "tool", label: "End date", date: field.value, onChange: field.onChange, fromDate: fromDate, toDate: maxStakeUnboundPeriod ? maxStakeDate : undefined })) }) }) }), _jsxs(InfoList, { view: "transparent", children: [_jsx(InfoRow, { title: "Selected duration", titleColor: "ghost", children: endTime &&
                            formatDuration(intervalToDuration({
                                start: new Date(),
                                end: endTime
                            }), {
                                format: ['years', 'months', 'days', 'hours']
                            }) }), maxStakeUnboundPeriod && (_jsx(InfoRow, { title: "Maximum duration", titleColor: "ghost", children: formatDuration(intervalToDuration({
                            start: new Date(),
                            end: maxStakeDate
                        }), {
                            format: ['years', 'months', 'days', 'hours', 'minutes']
                        }) }))] })] }));
};
