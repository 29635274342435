import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { AssetIcon, InfoAccount, InfoRow } from '@copper/components';
import { SwitchStateRow } from '@copper/components/Forms/SwitchStateRow';
import { selectCurrencies } from '@copper/entities/dictionaries/dictionaries-selector';
import { getCurrencyName } from '@copper/helpers';
import { useCurrenciesInfo } from '@copper/hooks/useCurrenciesInfo';
import { formatCurrency } from '@copper/utils';
import s from './Summary.module.css';
const Summary = ({ isSubmitting, data, onNext }) => {
    const { getDecimals } = useCurrenciesInfo();
    const { entities: currenciesEntities } = useSelector(selectCurrencies);
    const { amount, currency, mainCurrency, origin, destination, description } = data;
    if (!currency || !origin || !amount) {
        return null;
    }
    const currencyId = currency.currency;
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: s.container, children: _jsxs("div", { className: s.containerSummary, children: [_jsx("h2", { className: s.title, children: "Summary" }), _jsx(InfoRow, { title: "Asset", titleColor: "ghost", children: _jsxs("div", { className: s.asset, children: [getCurrencyName(currencyId, currenciesEntities), _jsx("span", { className: s.ticker, children: currencyId }), _jsx(AssetIcon, { size: "s", currency: currencyId })] }) }), _jsx(InfoRow, { title: "From", titleColor: "ghost", children: _jsx(InfoAccount, { account: origin }) }), destination && (_jsx(InfoRow, { title: "To", titleColor: "ghost", children: destination && _jsx(InfoAccount, { account: destination }) })), _jsx(InfoRow, { title: "You send", titleColor: "ghost", children: _jsxs("div", { className: s.youSend, children: [formatCurrency(amount, {
                                        decimalPlaces: getDecimals(currencyId, mainCurrency, {
                                            useDictionaryDecimal: true
                                        })
                                    }), ' ', currencyId] }) }), description && (_jsx(InfoRow, { title: "Optional note", titleColor: "ghost", children: description }))] }) }), _jsx(SwitchStateRow, { loading: isSubmitting, text: "Confirm", onClick: () => onNext() })] }));
};
export { Summary };
