import { omit } from 'lodash-es';
import { normalizeUser } from '../user/user-normalize';
const normalizeOrganizationMembers = (members) => members.reduce((acc, member) => {
    const { _embedded, extra, ...rootData } = member;
    const { user, permissionGroups, approvalRequests } = _embedded ?? {};
    const { blockExempt = false } = extra ?? {};
    if (!user) {
        return acc;
    }
    return {
        ...acc,
        [user.userId]: {
            ...omit(rootData, ['roles']), //exclude deprecated fields
            permissionGroups,
            isExemption: blockExempt,
            approvalRequests,
            user: normalizeUser(user)
        }
    };
}, {});
export const normalizeOrganization = (organization) => {
    const { _embedded, ...base } = organization;
    const { members = [], bankAccounts, counterparty, availableAuthSchemes, isAuthenticated } = _embedded ?? {};
    return {
        ...base,
        ...(counterparty && { counterparty: counterparty }),
        members: normalizeOrganizationMembers(members),
        bankAccounts,
        availableAuthSchemes,
        isAuthenticated: isAuthenticated ?? true
    };
};
