import { jsx as _jsx } from "react/jsx-runtime";
import cn from 'classnames';
import React from 'react';
import { DayPicker } from 'react-day-picker';
import styles from 'react-day-picker/dist/style.module.css';
import { MonthsDropdown } from './MonthsDropdown';
import { YearsDropdown } from './YearsDropdown';
import s from './DatePicker.module.css';
export const DATE_FORMAT = 'dd.MM.yyyy';
export const TIME_FORMAT = 'HH:mm';
const DatePicker = ({ mode = 'range', value, singleValue, onChange, onSingleChange, captionRef, startDate, endDate, view = 'primary' }) => {
    const classNames = {
        ...styles,
        month_grid: cn(s.monthGrid, s[`monthGrid_${view}`]),
        day: s.day,
        day_button: s.dayButton,
        outside: s.dayOutside,
        selected: s.selected,
        weekdays: s.weekdays,
        weekday: s.weekday,
        weeks: s.weeks,
        range_start: s.rangeStart,
        range_middle: s.rangeMiddle,
        range_end: s.rangeEnd,
        month: s.month,
        months: s.months,
        dropdowns: s.rdpDropdowns,
        root: s.root,
        disabled: s.disabled
    };
    const disabledDays = () => {
        if (startDate && endDate) {
            return { after: endDate, before: startDate };
        }
        if (startDate) {
            return { before: startDate };
        }
        if (endDate) {
            return { after: endDate };
        }
    };
    //Workaround for typescript err
    if (mode === 'single') {
        return (_jsx(DayPicker, { mode: "single", captionLayout: "dropdown", showOutsideDays: true, weekStartsOn: 1, selected: singleValue, onSelect: onSingleChange, startMonth: startDate, endMonth: endDate, disabled: disabledDays(), components: {
                MonthsDropdown: (props) => (_jsx(MonthsDropdown, { ...props, captionRef: captionRef, view: view })),
                YearsDropdown: (props) => _jsx(YearsDropdown, { ...props, captionRef: captionRef, view: view })
            }, classNames: classNames, hideNavigation: true }));
    }
    return (_jsx(DayPicker, { captionLayout: "dropdown", mode: mode, showOutsideDays: true, weekStartsOn: 1, selected: value, onSelect: onChange, startMonth: startDate, endMonth: endDate, disabled: disabledDays(), components: {
            MonthsDropdown: (props) => (_jsx(MonthsDropdown, { ...props, captionRef: captionRef, view: view })),
            YearsDropdown: (props) => _jsx(YearsDropdown, { ...props, captionRef: captionRef, view: view })
        }, classNames: classNames, hideNavigation: true, required: false }));
};
export { DatePicker };
