import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, generatePath } from 'react-router-dom';
import cn from 'classnames';
import { AssetIcon, PortfolioIcon } from '@copper/components';
import { VerificationStatus } from '@copper/components/AddressBook/Crypto/VerificationStatus';
import { isDepositAddress, isWithdrawalAddress } from '@copper/components/AddressBook/helpers';
import { selectUser } from '@copper/entities/user/user-selector';
import { Atom, IconGradientFiat, IconWhitelist } from '@copper/ui-kit';
import { AccountDataToCopy } from './AccountInfoToCopy';
import s from './InfoPortfolio.module.css';
const InfoAccount = ({ account, address, accountLabel, showLink = true, forceAccountTypeIcon = false, align = 'right' }) => {
    const { isAdmin } = useSelector(selectUser);
    if ('bankAccountId' in account) {
        const linkTo = isAdmin
            ? `/admin/organizations/${account.organizationId}/address-book/fiat/saved-accounts?addressId=${account.bankAccountId}`
            : `/settings/address-book/fiat/saved-accounts?addressId=${account.bankAccountId}`;
        return (_jsx(Atom, { align: "right", title: _jsx(NavLink, { to: linkTo, children: account.bankAccountName }), subtext: _jsx(AccountDataToCopy, { data: account.iban }), icon: _jsx(IconGradientFiat, {}) }));
    }
    // mostly for transfer form, when selected external, but not whitelisted - useful only for lite orgs
    if ('cryptoAddressId' in account && account.cryptoAddressId === '') {
        return (_jsx(Atom, { align: align, title: "External address", subtext: _jsx(AccountDataToCopy, { data: address }), icon: _jsx(PortfolioIcon, { portfolio: "external-address" }) }));
    }
    if ('cryptoAddressId' in account) {
        const linkTo = isAdmin
            ? `/admin/organizations/${account.organizationId}/address-book/crypto/addresses?addressId=${account.cryptoAddressId}`
            : `/settings/address-book/crypto/addresses?addressId=${account.cryptoAddressId}`;
        return (_jsx(_Fragment, { children: _jsxs("div", { className: s.portfolio, children: [_jsxs("div", { children: [_jsxs("div", { className: s.title, children: [account.isWhitelist && (_jsx("span", { className: s.whitelistIcon, children: _jsx(IconWhitelist, {}) })), showLink ? _jsx(NavLink, { to: linkTo, children: account.name }) : account.name] }), _jsx(AccountDataToCopy, { data: account.address }), isDepositAddress(account) && (_jsx("div", { className: s.verificationStatus, children: _jsx(VerificationStatus, { size: "s", address: account }) }))] }), isWithdrawalAddress(account) && !forceAccountTypeIcon && (_jsx("div", { className: s.icon, children: _jsx(AssetIcon, { size: "s", currency: account.currency }) })), forceAccountTypeIcon && _jsx(PortfolioIcon, { portfolio: "external-address" })] }) }));
    }
    if ('nonCustodialWalletId' in account) {
        return (_jsx(Atom, { align: align, title: account.name, subtext: _jsx(AccountDataToCopy, { data: account.address }), icon: _jsx(AssetIcon, { size: "s", currency: account.mainCurrency }) }));
    }
    if ('portfolioId' in account) {
        const linkPathTemplate = (() => {
            if (isAdmin && account.status === 'archived') {
                return `/admin/organizations/:organizationId/archived-accounts/:portfolioId`;
            }
            if (isAdmin) {
                return `/admin/organizations/:organizationId/accounts/:portfolioId`;
            }
            if (account.status === 'archived') {
                return '/settings/archived-accounts/:portfolioId';
            }
            return '/accounts/:portfolioId';
        })();
        const linkPath = generatePath(linkPathTemplate, {
            organizationId: account.organizationId ?? '',
            portfolioId: account.portfolioId
        });
        return (_jsxs("div", { className: cn(s.portfolio, { [s.alignRight]: align === 'right' }), children: [_jsx("div", { className: s.icon, children: _jsx(PortfolioIcon, { portfolio: account }) }), _jsxs("div", { children: [_jsx("div", { className: s.portfolioLabel, children: accountLabel }), _jsx(Atom, { title: showLink ? (_jsx(NavLink, { to: linkPath, children: account?.portfolioName })) : (account?.portfolioName), subtext: _jsx(AccountDataToCopy, { data: address }), align: align })] })] }));
    }
};
export { InfoAccount };
