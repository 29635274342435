import BigNumber from 'bignumber.js';
import { isClearloopPortfolio, isClearloopSubAccount, isCslPledgePortfolio, isExternalCustodianClearloop } from '@copper/helpers/portfolios';
import { fuzzySearch } from '@copper/ui-kit';
import { formatFiat } from '@copper/utils';
export const flattenMenuList = (menuList) => menuList.reduce((acc, value) => {
    if (!value.item.isShadow) {
        acc.push({ item: value.item, nestingLevel: 1, next: null, parentKeys: [] });
    }
    if (value.next) {
        acc = acc.concat(flattenMenuList(value.next));
    }
    return acc;
}, []);
const filterNestedItemsByArray = (items, filterItems) => items.reduce((acc, node) => {
    const filteredChildren = node.next ? filterNestedItemsByArray(node.next, filterItems) : null;
    if (filterItems.some((el) => el.item.portfolioId === node.item.portfolioId) ||
        filteredChildren?.length) {
        acc.push({
            ...node,
            next: filteredChildren
        });
    }
    return acc;
}, []);
export const filterPortfolios = ({ items = [], searchQuery, userFavoritePortfolios = {}, showFavorites = false, searchType = 'weak' }) => {
    if (searchQuery === '' && !showFavorites) {
        return items;
    }
    const flattenItems = flattenMenuList(items);
    const itemsToFilter = showFavorites
        ? flattenItems.filter((el) => userFavoritePortfolios[el.item.portfolioId]?.favorite)
        : flattenItems;
    const filteredItems = searchQuery
        ? fuzzySearch({
            searchQuery,
            keys: ['item.portfolioName'],
            items: itemsToFilter,
            searchType
        })
        : itemsToFilter;
    return filterNestedItemsByArray(items, filteredItems);
};
export const calculateBalance = (portfolios) => {
    const result = portfolios.reduce((acc, portfolio) => {
        if ((!isClearloopPortfolio(portfolio?.portfolioType) &&
            !isClearloopSubAccount(portfolio) &&
            !isCslPledgePortfolio(portfolio?.portfolioType)) ||
            isExternalCustodianClearloop(portfolio)) {
            return new BigNumber(acc).plus(portfolio.balance).toFixed();
        }
        return acc;
    }, '0');
    return formatFiat(result, { short: true });
};
