import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo } from 'react';
import { useController, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { AssetIcon } from '@copper/components';
import { getPortfolioBalanceInNetwork } from '@copper/components/Forms/Network/helpers';
import { selectCurrenciesEntities, selectTokenEntities } from '@copper/entities/currencies/currencies-selector';
import { selectNetworks } from '@copper/entities/networks/networks-selectors';
import { selectWalletsByPortfolioId } from '@copper/entities/wallets/wallets-selector';
import { getAccessibleMainCurrencies, getNetworkName, isCurrencyFiat } from '@copper/helpers';
import { FormControl, FormLabel, Select } from '@copper/ui-kit';
const Network = ({ control, required, errorMessage, name, currency, testId, view = 'primary', forceMainCurrencies, label = 'Network', portfolio, disabled, excludeCurrencyId, onChange }) => {
    const currenciesEntities = useSelector(selectCurrenciesEntities);
    const tokenEntities = useSelector(selectTokenEntities);
    const walletsByPortfolioId = useSelector(selectWalletsByPortfolioId);
    const networks = useSelector(selectNetworks);
    const walletsInCurrency = (walletsByPortfolioId[portfolio?.portfolioId ?? ''] ?? []).filter((wallet) => wallet.currency === currency);
    const isFiat = isCurrencyFiat(currenciesEntities, currency);
    const { field: { onChange: internalOnChange } } = useController({
        name,
        control
    });
    const value = useWatch({
        name,
        control
    });
    const mainCurrencies = useMemo(() => {
        const currencyList = Array.isArray(forceMainCurrencies)
            ? forceMainCurrencies
            : getAccessibleMainCurrencies(currenciesEntities, tokenEntities, currency);
        return currencyList.filter((currency) => currency !== excludeCurrencyId);
    }, [forceMainCurrencies, currenciesEntities, currency, tokenEntities, excludeCurrencyId]);
    useEffect(() => {
        if (mainCurrencies.includes(value)) {
            return;
        }
        else if (mainCurrencies.length === 1) {
            internalOnChange(mainCurrencies[0]);
        }
        else if (value !== undefined) {
            internalOnChange(undefined);
        }
    }, [value, mainCurrencies, required, internalOnChange]);
    const handleOnChange = (value) => {
        internalOnChange(value);
        onChange && onChange(value);
    };
    if (!required || isFiat) {
        return null;
    }
    return (_jsxs(FormControl, { errorMessage: errorMessage, children: [_jsx(FormLabel, { view: view, children: label }), _jsx(Select, { view: view, emptyDataString: "Select an asset to view available networks", placeholder: "Select network", items: mainCurrencies, getIcon: (mainCurrency) => (_jsx(AssetIcon, { currency: networks[mainCurrency]?.nativeCurrency, size: "s" })), getTopRow: (mainCurrency) => `${mainCurrency}`, getBottomRow: (mainCurrency) => portfolio && portfolio.portfolioType !== 'external' && currency
                    ? getPortfolioBalanceInNetwork(currency, mainCurrency, walletsInCurrency, tokenEntities)
                    : getNetworkName(mainCurrency, networks), onChange: handleOnChange, selected: value, getKey: (currency) => currency, searchPlaceholder: "Search networks", searchFields: ['currency', 'name'], emptySearchString: "No networks match this name", testId: testId, disabled: disabled })] }));
};
export { Network };
