import BigNumber from 'bignumber.js';
import * as Yup from 'yup';
import { getCurrencyDecimal } from '@copper/helpers';
import { isVaultPortfolio } from '@copper/helpers/portfolios';
import { validateAmountField } from '@copper/helpers/validation';
import { formatCurrency, formatFiat } from '@copper/utils';
export const validationSchema = Yup.object()
    .shape({
    clearLoopType: Yup.string().required('Select a type.'),
    currency: Yup.object().shape({ currency: Yup.string().required('Select an asset.') }),
    origin: Yup.object().shape({
        portfolioId: Yup.string().required('Select a ClearLoop account.')
    }),
    amount: validateAmountField()
})
    .required();
export const calculateAvailableBalance = (walletsByPortfolioId, portfolio, currencyId, mainCurrencyId) => {
    const portfolioWallets = walletsByPortfolioId[portfolio.portfolioId] ?? [];
    const wallets = portfolioWallets.filter((wallet) => {
        if (mainCurrencyId && isVaultPortfolio(portfolio.portfolioType)) {
            return wallet.currency === currencyId && wallet.mainCurrency === mainCurrencyId;
        }
        return wallet.currency === currencyId;
    });
    if (wallets.length === 0) {
        return;
    }
    return wallets.reduce((acc, wallet) => new BigNumber(acc).plus(wallet.available).toFixed(), '0');
};
export const getAvailableBalance = (walletsByPortfolioId, tokens, portfolio, currencyId, mainCurrencyId) => {
    if (!currencyId) {
        return formatFiat(portfolio.available);
    }
    const available = calculateAvailableBalance(walletsByPortfolioId, portfolio, currencyId, mainCurrencyId);
    if (available === undefined) {
        return portfolio.portfolioType === 'trading' ? `0.00 ${currencyId}` : 'Not activated';
    }
    return `${formatCurrency(available, {
        decimalPlaces: getCurrencyDecimal({ tokens, currencyId, mainCurrencyId })
    })} ${currencyId}`;
};
