import AllAccounts from './icons/all-accounts.svg';
import AscendEX from './icons/ascendex.svg';
import B2C2 from './icons/b2c2.svg';
import BinanceUS from './icons/binance-us.svg';
import Binance from './icons/binance.svg';
import Bitcom from './icons/bitcom.svg';
import Bitfinex from './icons/bitfinex.svg';
import Bitflyer from './icons/bitflyer.svg';
import Bitget from './icons/bitget.svg';
import Bithumb from './icons/bithumb.svg';
import Bitmart from './icons/bitmart.svg';
import Bitmex from './icons/bitmex.svg';
import Bitpanda from './icons/bitpanda.svg';
import Bitso from './icons/bitso.svg';
import Bitstamp from './icons/bitstamp.svg';
import Bittrex from './icons/bittrex.svg';
import Bitvavo from './icons/bitvavo.svg';
import BlockchainCom from './icons/blockchain-com.svg';
import Bullish from './icons/bullish.svg';
import Bybit from './icons/bybit.svg';
import Coinbase from './icons/coinbase.svg';
import Coinflex from './icons/coinflex.svg';
import CryptoCom from './icons/crypto-com.svg';
import Deribit from './icons/deribit.svg';
import Equos from './icons/equos.svg';
import Erisx from './icons/erisx.svg';
import ExternalAddress from './icons/external-address.svg';
import PortfolioFallback from './icons/fallback.svg';
import FeesVault from './icons/fees-vault.svg';
import FtxOtc from './icons/ftx-otc.svg';
import Ftx from './icons/ftx.svg';
import GateIO from './icons/gateio.svg';
import Gemini from './icons/gemini.svg';
import GlobeDX from './icons/globedx.svg';
import Globitex from './icons/globitex.svg';
import Gsr from './icons/gsr.svg';
import HitBtc from './icons/hitbtc.svg';
import Huobi from './icons/huobi.svg';
import IbanFirst from './icons/ibanfirst.svg';
import Itbit from './icons/itbit.svg';
import Kraken from './icons/kraken.svg';
import Kucoin from './icons/kucoin.svg';
import Liquid from './icons/liquid.svg';
import Lmax from './icons/lmax.svg';
import MultipleAccounts from './icons/multiple-accounts.svg';
import Okcoin from './icons/okcoin.svg';
import Okex from './icons/okex.svg';
import Poloniex from './icons/poloniex.svg';
import PowerTrade from './icons/powertrade.svg';
import Qcp from './icons/qcp.svg';
import Signet from './icons/signet.svg';
import Silvergate from './icons/silvergate.svg';
import ThirdPartyWalletIcon from './icons/third-party-wallet.svg';
import TradingVaultPortfolioIcon from './icons/trading-vault.svg';
import TradingPortfolioIcon from './icons/trading.svg';
import VaultPortfolioIcon from './icons/vault.svg';
import Xena from './icons/xena.svg';
export const portfolioIcons = {
    all: AllAccounts,
    multiple: MultipleAccounts,
    custody: VaultPortfolioIcon,
    'oxygen-vault': TradingVaultPortfolioIcon,
    'trading-vault': TradingVaultPortfolioIcon,
    trading: TradingPortfolioIcon,
    'external-address': ExternalAddress,
    'fees-vault': FeesVault,
    'external-wallets': ThirdPartyWalletIcon,
    fallback: PortfolioFallback,
    ascendex: AscendEX,
    b2c2: B2C2,
    'binance-us': BinanceUS,
    binance: Binance,
    bitcom: Bitcom,
    bitfinex: Bitfinex,
    bitget: Bitget,
    'bithumb-pro': Bithumb,
    bitmart: Bitmart,
    bitmex: Bitmex,
    bitso: Bitso,
    bitstamp: Bitstamp,
    bittrex: Bittrex,
    'blockchain-com': BlockchainCom,
    bullish: Bullish,
    'crypto-com': CryptoCom,
    deribit: Deribit,
    equos: Equos,
    erisx: Erisx,
    bitflyer: Bitflyer,
    'ftx-otc': FtxOtc,
    ftx: Ftx,
    gateio: GateIO,
    gemini: Gemini,
    globedx: GlobeDX,
    gsr: Gsr,
    hitbtc: HitBtc,
    huobi: Huobi,
    'iban-first': IbanFirst,
    itbit: Itbit,
    kraken: Kraken,
    kucoin: Kucoin,
    liquid: Liquid,
    lmax: Lmax,
    powertrade: PowerTrade,
    silvergate: Silvergate,
    // TODO check if these are ok
    'gsrm-self': Gsr,
    okex: Okex,
    okcoin: Okcoin,
    coinbase: Coinbase,
    'coinbase-prime': Coinbase,
    'coinbase-exchange': Coinbase,
    'coinbase-pro': Coinbase,
    poloniex: Poloniex,
    qcp: Qcp,
    'qcp-self': Qcp,
    'self-clearloop': Qcp,
    bybit: Bybit,
    coinflex: Coinflex,
    xena: Xena,
    signet: Signet,
    bitpanda: Bitpanda,
    bitvavo: Bitvavo,
    globitex: Globitex
};
