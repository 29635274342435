import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import useStateMachine from '@cassiozen/usestatemachine';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Totp } from '@copper/components/Auth/Totp';
import { Yubikey } from '@copper/components/Auth/Yubikey';
import { PageDetailLayout } from '@copper/components/Layouts/PageDetailLayout';
import { PageLayout } from '@copper/components/PageLayout';
import { SettingsItemLink } from '@copper/components/Settings/SettingsItemLink';
import { useTwoFaContext } from '@copper/contexts/twoFa';
import { updateToken } from '@copper/entities/auth/auth-reducer';
import { selectAuth } from '@copper/entities/auth/auth-selector';
import { changeUserOwnTwoFa } from '@copper/entities/user/user-reducer';
import { selectUserEmail } from '@copper/entities/user/user-selector';
import { generateSecret } from '@copper/helpers/auth';
import { Atom, Button, useSnackBar } from '@copper/ui-kit';
import { saveAuthData, setAuthorizationHeader } from '@copper/utils';
import googleAuthenticator from '@copper/components/Auth/icons/google-authenticator.svg';
import yubiKey from '@copper/components/Auth/icons/yubikey.svg';
import s from './ChangetVerificationMethod.module.css';
const yubikeyFormId = 'set-yubikey-form';
const totpFormId = 'set-totp-form';
export const ChangeVerificationMethod = () => {
    const navigate = useNavigate();
    const { openSnackbar } = useSnackBar();
    const dispatch = useDispatch();
    const email = useSelector(selectUserEmail);
    const { target } = useSelector(selectAuth);
    const { requestTwoFa, resolveTwoFa, twoFaErrorCatcher } = useTwoFaContext();
    const secret = useMemo(() => generateSecret(email), []);
    const [state, send] = useStateMachine({
        initial: 'selectMethod',
        states: {
            selectMethod: {
                on: {
                    SET_AUTHENTICATOR_APP: 'authenticatorApp',
                    SET_YUBIKEY: 'yubikey'
                }
            },
            authenticatorApp: {
                on: {
                    BACK: 'selectMethod'
                }
            },
            yubikey: {
                on: {
                    BACK: 'selectMethod'
                }
            }
        }
    });
    const { stepTitle, stepSubtitle, onBack } = useMemo(() => {
        if (state.value === 'authenticatorApp') {
            return {
                stepTitle: 'Set up authenticator',
                onBack: () => send('BACK')
            };
        }
        if (state.value === 'yubikey') {
            return {
                stepTitle: 'Set up security key',
                stepSubtitle: 'Touch your YubiKey or type your Yubico OTP manually to verify it’s you.',
                onBack: () => send('BACK')
            };
        }
        return {
            stepTitle: 'Choose a second verification method',
            stepSubtitle: 'This added layer of security will help protect your account.'
        };
    }, [state.value]);
    const handleSubmit = async (twoFa) => {
        try {
            if (!target) {
                return;
            }
            const twoFaCode = await requestTwoFa('change-2fa');
            const token = await dispatch(changeUserOwnTwoFa({ data: { ...twoFa, target }, twoFaCode })).unwrap();
            if (token) {
                await dispatch(updateToken({
                    token: token.token,
                    expiredAt: token.expiredAt
                }));
                setAuthorizationHeader(token.token);
                saveAuthData({
                    token: token.expiredAt,
                    expiredAt: token.expiredAt
                });
            }
            resolveTwoFa();
            navigate('..', { replace: true });
            openSnackbar({ message: 'Done! Your 2FA has been updated.', type: 'success' });
        }
        catch (error) {
            twoFaErrorCatcher(error, () => handleSubmit(twoFa));
        }
    };
    return (_jsx(PageLayout, { children: _jsxs(PageDetailLayout, { title: "Change verification method", onBack: onBack, onClose: () => navigate('..', { replace: true }), children: [_jsx("div", { className: s.stepTitle, children: stepTitle }), stepSubtitle && _jsx("div", { className: s.stepSubTitle, children: stepSubtitle }), state.value === 'selectMethod' && (_jsxs("div", { className: s.methods, children: [_jsx(SettingsItemLink, { view: "dark", onClick: () => {
                                send('SET_AUTHENTICATOR_APP');
                            }, children: _jsx(Atom, { title: "Authenticator app", subtext: "Use an authenticator app like Google Authenticator", icon: _jsx("img", { width: 20, src: googleAuthenticator, alt: "" }) }) }), _jsx(SettingsItemLink, { view: "dark", onClick: () => {
                                send('SET_YUBIKEY');
                            }, children: _jsx(Atom, { title: "Yubikey", subtext: "Set up a YubiKey security key", icon: _jsx("img", { width: 20, src: yubiKey, alt: "" }) }) })] })), state.value === 'authenticatorApp' && (_jsxs(_Fragment, { children: [_jsx(Totp, { hideSubmitButton: true, secret: secret, formId: totpFormId, onSuccess: (twoFaSecret) => handleSubmit({ twoFaSecret }) }), _jsxs("div", { className: s.buttons, children: [_jsx(Button, { view: "secondary", onClick: onBack, text: "Cancel" }), _jsx(Button, { form: totpFormId, type: "submit", text: "Save" })] })] })), state.value === 'yubikey' && (_jsxs(_Fragment, { children: [_jsx(Yubikey, { formId: yubikeyFormId, onSuccess: (yubiKey) => handleSubmit({ yubiKey }) }), _jsxs("div", { className: s.buttons, children: [_jsx(Button, { view: "secondary", onClick: onBack, text: "Cancel" }), _jsx(Button, { form: yubikeyFormId, type: "submit", text: "Save" })] })] }))] }) }));
};
