import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo, useState } from 'react';
import QRCode from 'qrcode';
import { INPUT_LENGTH, TwoFaCore } from '@copper/components/TwoFaCore';
import { verifyingToken } from '@copper/helpers/auth';
import { Button, ButtonIcon, ButtonLink, FormLabel, IconClipboard, useSnackBar } from '@copper/ui-kit';
import { copy } from '@copper/utils';
import { StepsList } from './StepsList';
import s from './Totp.module.css';
const Totp = ({ secret, onSuccess, formId = 'totp-form', hideSubmitButton = false }) => {
    const [currentView, setCurrentView] = useState('scanQR');
    const [qrCodeImage, setQrCodeImage] = useState();
    const { openSnackbar } = useSnackBar();
    const handleSubmit = () => {
        if (!secret) {
            return;
        }
        onSuccess(secret.base32);
    };
    useMemo(() => {
        if (!secret?.otpauth_url) {
            return;
        }
        QRCode.toDataURL(secret?.otpauth_url, { width: 400 }, (_error, dataUrl) => {
            setQrCodeImage(dataUrl);
        });
    }, [secret?.otpauth_url]);
    return (_jsxs("div", { className: s.container, children: [currentView === 'scanQR' && (_jsxs(_Fragment, { children: [_jsx(StepsList, { view: currentView }), qrCodeImage && (_jsxs("div", { className: s.qrCodeWrapper, children: [_jsx("img", { src: qrCodeImage, alt: "QRCode", className: s.qrCodeImg }), _jsx("div", { className: s.switchToManualButton, children: _jsx(ButtonLink, { text: "Can\u2019t scan code? Enter manually", onClick: () => setCurrentView('manual') }) })] }))] })), currentView === 'manual' && (_jsxs(_Fragment, { children: [_jsx(StepsList, { view: currentView }), _jsxs("div", { className: s.field, children: [_jsx("div", { className: s.fieldText, children: secret?.base32 || '' }), _jsx("div", { className: s.fieldButton, children: _jsx(ButtonIcon, { onClick: () => copy(secret?.base32 ?? '', () => openSnackbar({ type: 'success', message: 'Copied' })), size: "s", view: "ghost", tooltipLabel: "Copy to clipboard", children: _jsx(IconClipboard, { width: 20, height: 20 }) }) })] }), _jsx("div", { className: s.switchToQrButton, children: _jsx(ButtonLink, { text: "Scan QR code", onClick: () => setCurrentView('scanQR') }) })] })), _jsx(FormLabel, { children: "Verification code" }), _jsx(TwoFaCore, { formId: formId, onSuccess: handleSubmit, validate: (values) => {
                    if (!secret) {
                        return true;
                    }
                    const code = values.map((item) => item.value).join('');
                    if (code.length < INPUT_LENGTH) {
                        return 'Enter a verification code.';
                    }
                    if (code.length === INPUT_LENGTH) {
                        if (!verifyingToken(code, secret)) {
                            return 'Enter a valid verification code.';
                        }
                    }
                    return true;
                } }), !hideSubmitButton && (_jsx("div", { className: s.submitButton, children: _jsx(Button, { fullWidth: true, size: "xl", text: "Next", type: "submit", form: formId }) }))] }));
};
export { Totp };
