import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import cn from 'classnames';
import { ButtonIcon, IconAlert, IconInformation, Tooltip } from '@copper/ui-kit';
import { TableContext, useTable } from './TableContext';
import s from './Table.module.css';
const Table = ({ view, withTabs = false, noTopRounding = false, noBottomRounding = false, stretchToParentHeight = false, fixedLayout = false, className, children, testId }) => {
    const childContext = { view };
    return (_jsx(TableContext.Provider, { value: childContext, children: _jsx("table", { className: cn(s.table, withTabs && s.table_with_tabs, noTopRounding && s.noTopRounding, noBottomRounding && s.noBottomRounding, stretchToParentHeight && s.tableFullHeight, fixedLayout && s.tableLayout_fixed, className), "data-testid": testId, children: children }) }));
};
const Thead = ({ children }) => (_jsx("thead", { children: _jsx("tr", { children: children }) }));
const Tbody = ({ children }) => _jsx("tbody", { className: s.tbody, children: children });
const Tr = ({ children, gradientColor = 'base', className, onClick, selected = false, testId }) => {
    const { view } = useTable();
    return (_jsx("tr", { onClick: onClick, className: cn(s.tr, s[`tr_view_${view}`], s[`gradient_${gradientColor}`], className, {
            [s.hasHover]: Boolean(onClick),
            [s.selected]: selected
        }), "data-testid": testId, children: children }));
};
const Th = ({ colspan, align, valign, width = '', children, className }) => {
    const { view } = useTable();
    return (_jsx("th", { colSpan: colspan, className: cn(s.th, s[`th_view_${view}`], align && s[`align_${align}`], valign && s[`valign_${valign}`], className), style: { width }, children: children }));
};
const Td = ({ colspan, rowSpan, align, valign, width = '', children, className, testId }) => (_jsx("td", { rowSpan: rowSpan, colSpan: colspan, className: cn(s.td, align && s[`align_${align}`], valign && s[`valign_${valign}`], className), style: { width }, "data-testid": testId, children: children }));
const TdDoubleRowContent = ({ text, textWithoutAccent, subText }) => (_jsxs(_Fragment, { children: [_jsx("div", { className: cn(s.text, { [s.without_accent]: textWithoutAccent }), children: text }), Boolean(subText) && _jsx("div", { className: s.subText, children: subText })] }));
const TableEmptyRow = ({ children }) => (_jsx(Tr, { children: _jsx(Td, { className: s.emptyRow, colspan: 15, children: children }) }));
const TableCaption = ({ title, children, titleSize = 'm', description, tooltipInfo, tooltipIconType = 'info', tooltipPlacement = 'top', withBottomBorder = false }) => (_jsxs("div", { className: cn(s.caption, withBottomBorder && s.captionWithBottomBorder), children: [_jsxs("div", { children: [_jsxs("div", { className: s.captionTitleContainer, children: [_jsx("h3", { className: cn(s.captionTitle, s[`captionTitle_${titleSize}`]), children: title }), ' ', tooltipInfo && (_jsx(Tooltip, { type: "hint", label: tooltipInfo, placement: tooltipPlacement, children: _jsx(ButtonIcon, { children: tooltipIconType === 'warning' ? (_jsx(IconAlert, { type: "warning", width: 16, height: 16 })) : (_jsx(IconInformation, { width: 16, height: 16 })) }) }))] }), description && _jsx("div", { className: s.captionDescription, children: description })] }), _jsx("div", { className: s.captionRightSection, children: children })] }));
const TableContainer = ({ children, stretchToParentHeight = false, simulateTableBackground = false }) => (_jsxs("div", { className: cn(s.tableContainer, stretchToParentHeight && s.tableContainerStretched), children: [children, simulateTableBackground && _jsx("div", { className: s.tableContainerBgFiller })] }));
export { Table, TableCaption, TableContainer, TableEmptyRow, Tbody, Td, TdDoubleRowContent, Th, Thead, Tr };
