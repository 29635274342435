import { request } from '@copper/utils';
export const getWithdrawTargets = ({ portfolioId, ...params }) => request.get(`/portfolios/${portfolioId}/withdraw-targets`, {
    params
});
export const updatePortfolioBalances = (portfolioId) => request.patch(`/portfolios/${portfolioId}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.update-portfolio-balances+json'
    }
});
export const reconcileAutoDiscovery = (portfolioId) => request.patch(`/portfolios/${portfolioId}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.reconcile-auto-discovery+json'
    }
});
export const updatePortfolioWhiteLock = (portfolioId, whiteLockEnabled) => request.patch(`/portfolios/${portfolioId}`, {
    whiteLockEnabled
}, {
    headers: {
        'Content-Type': 'application/vnd.update+whitelock+settings+json'
    }
});
export const archivePortfolio = (portfolioId) => request.delete(`/portfolios/${portfolioId}`);
export const unarchivePortfolio = (portfolioId) => request.patch(`/portfolios/${portfolioId}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.activate-portfolio+json'
    }
});
export const getPortfolios = (params) => request.get(`/portfolios`, {
    params
});
export const getPortfolio = (portfolioId) => request.get(`/portfolios/${portfolioId}`);
export const create = (data) => request.post('/portfolios', data);
export const updateExternalPortfolio = ({ portfolioId, ...portfolioParams }) => request.patch(`/portfolios/${portfolioId}`, {
    ...portfolioParams
}, {
    headers: {
        'Content-Type': 'application/vnd.update-external-portfolio+json'
    }
});
export const updatePortfolio = ({ portfolioId, ...portfolioParams }) => request.patch(`/portfolios/${portfolioId}`, {
    ...portfolioParams
}, {
    headers: {
        'Content-Type': 'application/vnd.update-portfolio-settings+json'
    }
});
export const unbindEligibility = (portfolioId) => request.get(`/clearloop/portfolios/${portfolioId}/unbind-eligibility`);
export const updateFoPPortfolioSettings = (portfolioId, data) => request.patch(`/portfolios/${portfolioId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.update+free-of-payment-settlement+settings+json'
    }
});
export const getShards = ({ portfolioId }) => request.get(`/portfolios/${portfolioId}/shards`);
export const createShard = ({ activationCode, portfolioId }) => request.post(`/portfolios/${portfolioId}/shards`, { activationCode });
export const convertShardToWarm = (data, portfolioId, shardId) => request.patch(`/portfolios/${portfolioId}/shards/${shardId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.upload-shard-key+json'
    }
});
export const getShardCreationSessions = (portfolioId) => request.get(`/portfolios/${portfolioId}/shards-sessions`);
export const createShardCreationSession = (portfolioId, data) => request.post(`/portfolios/${portfolioId}/shards-sessions`, data);
export const cancelShardCreationSession = (portfolioId, sessionId) => request.patch(`/portfolios/${portfolioId}/shards-sessions/${sessionId}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.shards.cancel-shard-creation-session+json'
    }
});
export const createBoundedToken = (portfolioId) => request.post(`/portfolios/${portfolioId}/bounded-tokens`);
