import { BigNumber } from 'bignumber.js';
const abbreviations = {
    thousand: 'K',
    million: 'M',
    billion: 'B',
    trillion: 'T'
};
const powers = {
    trillion: Math.pow(10, 12),
    billion: Math.pow(10, 9),
    million: Math.pow(10, 6),
    thousand: Math.pow(10, 3)
};
const fiatCurrency = {
    GBP: {
        symbol: '£',
        position: 'prefix'
    },
    EUR: {
        symbol: '€',
        position: 'prefix'
    },
    USD: {
        symbol: '$',
        position: 'prefix'
    }
};
export const formatFiat = (value, { short = false, currencyCode = 'USD', signed = false, decimalPlaces = 2 } = {}) => formatCurrency(value, {
    decimalPlaces,
    short,
    signed,
    ...fiatCurrency[currencyCode]
});
export const formatShortCurrency = (value, signed = false) => {
    const decimalPlaces = new BigNumber(value).isLessThan(1) ? 4 : 2;
    return formatCurrency(value, { decimalPlaces, short: true, signed });
};
export const formatCurrency = (value, options) => {
    const { decimalPlaces, symbol, position, short = false, zeroFormat = '0.00', signed = false, roundingMode = BigNumber.ROUND_DOWN } = options || {};
    let abbreviation = '';
    if (short) {
        const data = computeShortNumber(value);
        value = data.value;
        abbreviation = data.abbreviation;
    }
    let output = formatNumber({ value, decimalPlaces, abs: signed, roundingMode });
    if (new BigNumber(output).isZero()) {
        output = zeroFormat;
    }
    if (abbreviation) {
        output = output + abbreviation;
    }
    if (symbol) {
        if (position === 'prefix') {
            output = output.startsWith('-') ? `-${symbol}${output.slice(1)}` : `${symbol}${output}`;
        }
        else if (!position || position === 'postfix') {
            output = `${output}${symbol}`;
        }
    }
    return signed ? toSigned(output, value) : output;
};
export const toSigned = (value, srcValue) => {
    const bnValue = new BigNumber(srcValue ? srcValue : value);
    if (value?.toString().startsWith('-') || value?.toString().startsWith('+')) {
        return value.toString();
    }
    return bnValue.isNegative() ? `-${value}` : `+${value}`;
};
export const toSignedOrderAmount = (value, orderType) => {
    if ([
        'deposit',
        'retrieved-deposit',
        'buy',
        'earn-reward',
        'release-collateral',
        'cross-chain-deposit'
    ].includes(orderType)) {
        value = `+${value}`;
    }
    else if ([
        'withdraw',
        'cross-chain-withdraw',
        'sell',
        'multi-withdraw',
        'loan-repayment',
        'secure-collateral',
        'claim-collateral',
        'loan-disbursement',
        'transfer-collateral',
        'return-collateral'
    ].includes(orderType)) {
        value = `-${value}`;
    }
    return value;
};
export const trimTrailingZeroes = (value) => {
    const [currentCharacteristic, currentMantissa = ''] = value.split('.');
    if (currentMantissa.match(/^0+$/)) {
        return currentCharacteristic;
    }
    const hasTrailingZeroes = currentMantissa.match(/0+$/);
    if (hasTrailingZeroes) {
        return `${currentCharacteristic}.${currentMantissa
            .toString()
            .slice(0, hasTrailingZeroes.index)}`;
    }
    return value;
};
export const formatNumber = ({ value, decimalPlaces, abs = false, roundingMode }) => {
    let bigValue = new BigNumber(value);
    if (abs) {
        bigValue = new BigNumber(bigValue.abs());
    }
    const result = decimalPlaces
        ? bigValue.toFormat(decimalPlaces, roundingMode ?? BigNumber.ROUND_DOWN)
        : bigValue.toFormat();
    return trimTrailingZeroes(result.toString());
};
const computeShortNumber = (value) => {
    let abbreviation = '';
    let bigValue = new BigNumber(value);
    const abs = bigValue.abs();
    if (abs.gte(powers.trillion)) {
        // trillion
        abbreviation = abbreviations.trillion;
        bigValue = bigValue.div(powers.trillion);
    }
    else if (abs.lt(powers.trillion) && abs.gte(powers.billion)) {
        // billion
        abbreviation = abbreviations.billion;
        bigValue = bigValue.div(powers.billion);
    }
    else if (abs.lt(powers.billion) && abs.gte(powers.million)) {
        // million
        abbreviation = abbreviations.million;
        bigValue = bigValue.div(powers.million);
    }
    else if (abs.lt(powers.million) && abs.gte(powers.thousand)) {
        // thousand
        abbreviation = abbreviations.thousand;
        bigValue = bigValue.div(powers.thousand);
    }
    return { value: bigValue.toFixed(), abbreviation };
};
export const percentageToBPS = (percentage) => new BigNumber(percentage || '0').multipliedBy(100).toString(10) + ' BPS';
