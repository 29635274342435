import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormLabel, Select } from '@copper/ui-kit';
import { addYears, format, setMonth, setYear, startOfMonth, startOfYear, subYears } from 'date-fns';
import { useMemo } from 'react';
import { useDayPicker } from 'react-day-picker';
import s from '../DatePicker.module.css';
export const MonthsDropdown = ({ captionRef, view }) => {
    const { dayPickerProps, goToMonth, months } = useDayPicker();
    const fromMonth = dayPickerProps.startMonth || subYears(new Date(), 3);
    const toMonth = dayPickerProps.endMonth || addYears(new Date(), 10);
    const dropdownYears = [];
    const dropdownMonths = [];
    for (let month = 0; month <= 11; month++) {
        dropdownMonths.push(setMonth(startOfMonth(new Date()), month));
    }
    const fromFullYear = fromMonth?.getFullYear();
    const toFullYear = toMonth?.getFullYear();
    for (let year = fromFullYear; year <= toFullYear; year++) {
        dropdownYears.push(setYear(startOfYear(new Date()), year));
    }
    const handleChangeMonth = (value) => {
        if (value != null) {
            const newDate = new Date(months[0].date.setMonth(value.getMonth()));
            goToMonth(newDate);
        }
    };
    const selectedToDisplay = useMemo(() => months[0].date, []);
    return (_jsxs("div", { className: s.dropwdownContainer, children: [_jsx(FormLabel, { children: "Month" }), _jsx(Select, { dropwdownRef: captionRef, size: "m", items: dropdownMonths, onChange: handleChangeMonth, selected: selectedToDisplay, getKey: (month) => month.getMonth().toString(), getBottomRow: (month) => format(month, 'LLLL'), view: view })] }));
};
