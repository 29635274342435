import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import * as approvalRequestsApi from '@copper/api/approvalRequests';
import * as dictionariesApi from '@copper/api/dictionaries';
import { normalizeDepositBankAccounts } from './depositBankAccounts-normalize';
export const fetchDepositBankAccounts = createAsyncThunk('depositBankAccounts/get', async (params, thunkAPI) => {
    try {
        const response = await dictionariesApi.getDepositBankAccounts(params);
        return response.data.depositBankAccounts;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const fetchBankAccountsApprovalRequests = createAsyncThunk('approvalRequests/fetchBankAccountsRequests', async ({ userId }, thunkAPI) => {
    try {
        const params = {
            userId,
            actionNames: ['update-deposit-bank-account', 'create-deposit-bank-account'],
            requestStatus: 'waiting-approval'
        };
        const response = await approvalRequestsApi.getApprovalRequests(params);
        return response.data.requests;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const reviewBankAccountsApprovalRequests = createAsyncThunk('approvalRequests/reviewBankAccountsRequest', async ({ requestId, data }, thunkAPI) => {
    try {
        const response = await approvalRequestsApi.updateApprovalRequestReview(requestId, data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const cancelBankAccountsApprovalRequests = createAsyncThunk('approvalRequests/cancelBankAccountsRequest', async (requestId, thunkAPI) => {
    try {
        const response = await approvalRequestsApi.cancelApprovalRequest(requestId);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const depositBankAccountsSlice = createSlice({
    name: 'depositBankAccounts',
    initialState: {
        bankAccounts: {},
        approvalRequests: {}
    },
    reducers: {},
    extraReducers: ({ addCase, addMatcher }) => {
        addCase(fetchDepositBankAccounts.fulfilled, (state, { payload }) => {
            state.bankAccounts = normalizeDepositBankAccounts(payload);
        });
        addCase(fetchBankAccountsApprovalRequests.fulfilled, (state, { payload }) => {
            state.approvalRequests = payload.reduce((acc, request) => ({
                ...acc,
                [request.requestId]: request
            }), {});
        });
        addMatcher(isAnyOf(reviewBankAccountsApprovalRequests.fulfilled, cancelBankAccountsApprovalRequests.fulfilled), (state, { payload }) => {
            delete state.approvalRequests[payload.requestId];
        });
    }
});
export const depositBankAccounts = depositBankAccountsSlice.reducer;
