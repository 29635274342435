import { jsx as _jsx } from "react/jsx-runtime";
import { LOAN_TYPES_MAP } from '@copper/components/Loans/constants';
import { IconGradientOrganisation, IconLogo } from '@copper/ui-kit';
import BigNumber from 'bignumber.js';
import { format, formatDistanceToNow, isPast } from 'date-fns';
export const displayTimeToMaturityDate = (loan) => {
    if (loan.terminatedAt) {
        return `Closed ${format(parseInt(loan.terminatedAt), `dd MMM yyyy`)}`;
    }
    if (loan.maturityDate) {
        return formatDistanceToNow(parseInt(loan.maturityDate), { addSuffix: true });
    }
    return '';
};
export const isMaturityExpired = (loan) => loan.maturityDate ? isPast(parseInt(loan.maturityDate)) : false;
export const displayMaturityDate = (loan) => loan.maturityDate ? format(parseInt(loan.maturityDate), `dd MMM yyyy`) : 'Open';
export const getLoanAllocationStatusObject = (loanAllocation) => {
    const { status, _embedded } = loanAllocation;
    switch (status) {
        case 'new':
        case 'order-created':
        case 'requiring-manual-intervention':
            return {
                status: 'Processing',
                color: 'orange'
            };
        case 'failed':
            return {
                status: 'Failed',
                color: 'red'
            };
        case 'completed':
            return {
                status: 'Completed',
                color: 'base'
            };
        case 'awaiting-funds':
            return {
                status: 'Awaiting funds',
                color: 'orange'
            };
        case 'awaiting-signature':
            return {
                status: 'Awaiting signature',
                color: _embedded?.isSignable ? 'oxide' : 'orange'
            };
        case 'awaiting-acceptance':
            return {
                status: 'Awaiting acceptance',
                color: _embedded?.isAcceptable ? 'oxide' : 'orange'
            };
        case 'awaiting-confirmation':
            return {
                status: 'Awaiting confirmation',
                color: _embedded?.isAcceptable ? 'oxide' : 'orange'
            };
        default:
            return {
                status: status?.split('-').join(' '),
                color: 'base'
            };
    }
};
export const sortLoanAllocationByCreatedAt = (transactionA, transactionB) => Number(transactionB?.createdAt ?? 0) - Number(transactionA?.createdAt ?? 0);
export const sortLoanModificationByCreatedAt = (requestA, requestB) => Number(requestB?.createdAt ?? 0) - Number(requestA?.createdAt ?? 0);
export const countProcessingTransactions = (loan) => {
    const { loanAllocations } = loan?._embedded || {};
    return loanAllocations?.length && loanAllocations?.length > 0
        ? loanAllocations.reduce((inProgress, allocation) => {
            if (['new', 'order-created', 'requiring-manual-intervention'].includes(allocation.status)) {
                inProgress++;
            }
            return inProgress;
        }, 0)
        : 0;
};
export const countPendingRequests = (loan) => {
    const { loanModifications = [] } = loan?._embedded || {};
    return loanModifications.filter(({ status }) => status === 'awaiting-acceptance').length;
};
export const buildLoanTransactionLink = (loanAllocation, organizationId, isAdmin) => {
    const pathname = `${isAdmin ? `/admin/organizations/${organizationId}` : ''}/accounts/all/transactions`;
    const { orderId, counterpartyOrderId } = loanAllocation;
    const searchPrefix = `orderId=`;
    const orderToRender = orderId ?? counterpartyOrderId;
    const orderIdLink = {
        pathname,
        search: `${searchPrefix}${orderToRender}`,
        text: orderToRender ?? ''
    };
    return orderIdLink;
};
export const isOpenLendingLoan = (loan) => {
    return ![
        'awaiting-acceptance',
        'awaiting-collateral',
        'securing-collateral',
        'defaulted',
        'cancelled',
        'errored',
        'repaid',
        'closed'
    ].includes(loan.status);
};
export const getLoanCounterpartyBorrowersIdsForLendingTableView = (loan) => {
    switch (loan.loanType) {
        case 'bilateral-lending-title-transfer-collateralised-loan':
            return [loan.borrowerCounterpartyId];
        case 'agency-lending-parent-loan':
            return loan._embedded?.borrowersCounterpartyIds;
        default:
            return [loan.borrowerCounterpartyId];
    }
};
export const isLoanWithCollateralType = (loanType) => LOAN_TYPES_MAP[loanType]?.hasCollateral;
export const isCollateralPortfolioRequired = ({ loan, repaymentAmount, organizationId }) => {
    const { amount: loanAmount, borrowerOrganizationId } = loan;
    const isBorrower = borrowerOrganizationId === organizationId;
    return (isBorrower &&
        BigNumber(loanAmount).eq(repaymentAmount ?? 0) &&
        isLoanWithCollateralType(loan.loanType));
};
export const isAgencyLendingType = (loanType) => LOAN_TYPES_MAP[loanType]?.lendingType === 'Agency lending';
export const isAgencyChildLoanType = (loanType) => LOAN_TYPES_MAP[loanType]?.isAgencyChildLoan;
export const loanLabel = (loanType) => LOAN_TYPES_MAP[loanType]?.label;
export const loanLendingTypeLabel = (loanType) => LOAN_TYPES_MAP[loanType]?.lendingType;
export const isBilateralLoanType = (loanType) => LOAN_TYPES_MAP[loanType]?.isBilateral;
export const getLendingTypeIcon = (lendingType, size) => {
    if (!lendingType)
        return null;
    switch (lendingType) {
        case 'Agency lending':
            return _jsx(IconLogo, { width: size, height: size, viewBox: "0 0 32 32" });
        case 'Bilateral lending':
            return _jsx(IconGradientOrganisation, { width: size, height: size });
        default:
            return null;
    }
};
export const collateralArrangementForLoanType = (loanType) => {
    switch (loanType) {
        case 'bilateral-lending-pledge-collateralised-loan':
            return 'pledge';
        case 'bilateral-lending-title-transfer-collateralised-loan':
            return 'title-transfer';
        default:
            return undefined;
    }
};
