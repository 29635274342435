import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import { format } from 'date-fns';
import { AssetIcon, InfoAccount, InfoRow } from '@copper/components';
import { selectRates } from '@copper/entities/currencyRates/currencyRates-selector';
import { useCurrenciesInfo } from '@copper/hooks/useCurrenciesInfo';
import { Button, IconInformation, IconLogo, Tooltip } from '@copper/ui-kit';
import { formatCurrency, formatFiat } from '@copper/utils';
import { MATURITY_DATE_DEFINITION } from '@copper/components/Loans/constants';
import { selectNetworks } from '@copper/entities/networks/networks-selectors';
import s from './Summary.module.css';
export const Summary = ({ onSubmit }) => {
    const { getValues, formState: { isSubmitting } } = useFormContext();
    const { getDecimals } = useCurrenciesInfo();
    const currenciesRates = useSelector(selectRates);
    const networks = useSelector(selectNetworks);
    const { currency, amount, maturityDate, portfolio, mainCurrency } = getValues();
    const amountMarketValue = BigNumber(amount)
        .times(currenciesRates[currency?.currency ?? ''] ?? 0)
        .toFixed();
    return (_jsxs("div", { className: s.mainWrapper, children: [_jsxs("div", { className: s.summaryContent, children: [_jsx("h2", { className: s.summaryTitle, children: "Summary" }), _jsx(InfoRow, { title: "Type", titleColor: "ghost", children: _jsxs("div", { className: s.loanType, children: ["Agency lending ", _jsx(IconLogo, { width: 22, height: 22, viewBox: "0 0 32 32" })] }) }), _jsx(InfoRow, { title: "Account", titleColor: "ghost", children: _jsx(InfoAccount, { account: portfolio, address: portfolio.accountId }) }), _jsx(InfoRow, { title: "Amount", titleColor: "ghost", children: _jsxs("div", { className: s.assetContainer, children: [_jsxs("div", { className: s.assetWrapper, children: [_jsxs("div", { className: s.asset, children: [formatCurrency(amount || '0', {
                                                    decimalPlaces: getDecimals(currency.currency, networks[mainCurrency]?.nativeCurrency, { useDictionaryDecimal: true })
                                                }), _jsx("span", { children: currency.currency })] }), _jsx("div", { className: s.assetValue, children: formatFiat(amountMarketValue) })] }), _jsx(AssetIcon, { size: "s", currency: currency.currency })] }) }), _jsx(InfoRow, { titleColor: "ghost", title: _jsxs("div", { className: s.maturityDateLabel, children: ["Maturity date", maturityDate && (_jsx(Tooltip, { view: "tool", label: MATURITY_DATE_DEFINITION, placement: "top", children: _jsx(IconInformation, { width: 12 }) }))] }), children: maturityDate ? format(maturityDate, `dd MMM yyyy`) : 'Open term' })] }), _jsx("div", { className: s.footer, children: _jsx(Button, { text: "Confirm", onClick: onSubmit, loading: isSubmitting, testId: "new-loan-submit-button" }) })] }));
};
