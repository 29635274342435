export const getBankAccountFields = () => [
    {
        value: 'bankAccountType',
        label: 'Bank type'
    },
    {
        value: 'bankAccountName',
        label: 'Bank account name'
    },
    {
        value: 'currency',
        label: 'Currency'
    },
    {
        value: 'beneficiaryAccountName',
        label: 'Beneficiary name'
    },
    {
        value: 'beneficiaryAccountAddress',
        label: 'Beneficiary address'
    },
    {
        value: 'beneficiaryAccountPostalCode',
        label: 'Beneficiary postcode'
    },
    {
        value: 'beneficiaryAccountCity',
        label: 'Beneficiary city'
    },
    {
        value: 'beneficiaryAccountCountry',
        label: 'Beneficiary country'
    },
    {
        value: 'beneficiaryReference',
        label: 'Beneficiary reference'
    },
    {
        value: 'iban',
        label: 'IBAN/Account number'
    },
    {
        value: 'swift',
        label: 'Swift/BIC code'
    },
    {
        value: 'bankName',
        label: 'Bank name'
    },
    {
        value: 'bankAddress',
        label: 'Bank address'
    },
    {
        value: 'bankPostalCode',
        label: 'Bank postcode'
    },
    {
        value: 'bankCity',
        label: 'Bank city'
    },
    {
        value: 'bankCountry',
        label: 'Bank country'
    },
    {
        value: 'comment',
        label: 'Notes'
    }
];
export const BANK_ROWS_MAP = new Map([
    ['accountDescription', 'Account description'],
    ['currency', 'Currency'],
    ['accountName', 'Account name'],
    ['recipientAddress', 'Recipient address'],
    ['intermediaryBank', 'Intermediary bank'],
    ['usCorrespondentBank', 'US correspondent bank'],
    ['bankName', 'Bank name'],
    ['iban', 'IBAN'],
    ['accountNumber', 'Account number'],
    ['swiftCode', 'SWIFT'],
    ['aba', 'Routing/ABA'],
    ['bankAddress', 'Bank address'],
    ['sortCode', 'Sort code'],
    ['referenceNumber', 'Reference'],
    ['targetGlAccount', 'ZAG GL Code']
]);
