import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as usersApi from '@copper/api/users';
import { selectUser, selectUserId, selectUserIdentities } from '@copper/entities/user/user-selector';
import { getImageSrcById } from '@copper/helpers';
import { getKeyValue, request, setKeyValue } from '@copper/utils';
import { normalizeCurrentUser, normalizeUser, normalizeUserIdentities } from './user-normalize';
const initialState = {
    firstName: '',
    lastName: '',
    userId: '',
    userType: '',
    createdAt: '',
    identities: {},
    userProfile: {},
    isAdmin: false
};
export const fetchUser = createAsyncThunk('user/getUser', async (_, thunkAPI) => {
    const userId = selectUserId(thunkAPI.getState());
    try {
        const response = await usersApi.getUser({
            userId,
            includeUserProfile: true,
            includePermissions: true
        });
        const user = normalizeCurrentUser(response.data);
        let userImageB64;
        try {
            if (user.userProfile?.imageId) {
                const userImage = await request.get(getImageSrcById(user.userProfile?.imageId), {
                    responseType: 'blob'
                });
                userImageB64 = await new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = async () => {
                        resolve(reader.result);
                    };
                    reader.onerror = (error) => {
                        reject(error);
                    };
                    reader.readAsDataURL(userImage.data);
                });
            }
        }
        catch (error) {
            console.error(error);
        }
        if (user?.firstName) {
            setKeyValue('reLoginData', {
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.identities?.email?.[0]?.value,
                userImage: userImageB64
            });
        }
        return user;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const changePassword = createAsyncThunk('user/changePassword', async (payload, thunkAPI) => {
    const userId = selectUserId(thunkAPI.getState());
    try {
        const { data } = await usersApi.changePassword(userId, payload);
        return data?._embedded?.token;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const resetPassword = createAsyncThunk('user/resetPassword', async (data, thunkApi) => {
    try {
        await usersApi.resetCredentials(data.userId, data.credentials);
    }
    catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});
export const changeUserOwnTwoFa = createAsyncThunk('user/changeTwoFa', async (payload, thunkAPI) => {
    const userId = selectUserId(thunkAPI.getState());
    try {
        const { data } = await usersApi.changeTwoFa(userId, payload.data, payload.twoFaCode);
        return data?._embedded?.token;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const resetTwoFa = createAsyncThunk('user/resetTwoFa', async (data, thunkApi) => {
    try {
        await usersApi.resetCredentials(data.userId, data.credentials);
    }
    catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});
export const updateNotificationsSettings = createAsyncThunk('user/updateNotificationsSettings', async (data, thunkAPI) => {
    const userId = selectUserId(thunkAPI.getState());
    try {
        const response = await usersApi.updateNotificationsSettings(userId, data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const createTelegramToken = createAsyncThunk('user/createTelegramToken', async (_, thunkAPI) => {
    const userId = selectUserId(thunkAPI.getState());
    const userIdentities = selectUserIdentities(thunkAPI.getState());
    try {
        const response = await usersApi.createTelegramToken(userId);
        const newUserIdentity = response.data;
        thunkAPI.dispatch(setUserIdentities({
            ...userIdentities,
            [newUserIdentity.type]: newUserIdentity
        }));
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const uploadProfileImage = createAsyncThunk('user/uploadProfileImage', async (data, thunkAPI) => {
    try {
        const userId = selectUserId(thunkAPI.getState());
        const response = await usersApi.uploadProfileImage(userId, data);
        const newUserProfileImage = response.data?.imageId;
        await thunkAPI.dispatch(updateUser({ imageId: newUserProfileImage }));
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const deleteProfileImage = createAsyncThunk('user/deleteProfileImage', async (_, thunkAPI) => {
    try {
        const user = selectUser(thunkAPI.getState());
        if (user.userProfile?.imageId) {
            await usersApi.deleteProfileImage(user.userId, user.userProfile.imageId);
        }
        await thunkAPI.dispatch(updateUser({ imageId: '' }));
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const updateUserInformation = createAsyncThunk('user/updateUserInformation', async (data, thunkAPI) => {
    try {
        const res = await usersApi.updateUserInformation(data.userId, data);
        const updatedUser = res.data;
        const prevSavedValues = getKeyValue('reLoginData');
        setKeyValue('reLoginData', {
            ...prevSavedValues,
            firstName: updatedUser.firstName,
            lastName: updatedUser.lastName
        });
        await thunkAPI.dispatch(updateUser(updatedUser));
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const createPasskeyIdentity = createAsyncThunk('user/createPasskeyIdentity', async (data, thunkAPI) => {
    try {
        const userId = selectUserId(thunkAPI.getState());
        const response = await usersApi.createPasskey(userId, data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const getPasskeyIdentities = createAsyncThunk('user/getPasskeyIdentities', async (_, thunkAPI) => {
    try {
        const userId = selectUserId(thunkAPI.getState());
        const response = await usersApi.getUserIdentities(userId, { params: { type: 'passkey' } });
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const updatePasskeyIdentity = createAsyncThunk('user/updatePasskeyIdentity', async (data, thunkAPI) => {
    try {
        const userId = selectUserId(thunkAPI.getState());
        const response = await usersApi.updatePasskeyIdentity(userId, data.passkey, data.updatedData);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const deletePasskeyIdentity = createAsyncThunk('user/deletePasskeyIdentity', async (passkey, thunkAPI) => {
    try {
        const userId = selectUserId(thunkAPI.getState());
        await usersApi.deletePasskey(userId, passkey);
        return passkey;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const activateUser = createAsyncThunk('user/activateUser', async ({ userId, data }, thunkAPI) => {
    try {
        const response = await usersApi.activateUser(userId, data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => ({
            ...state,
            ...normalizeUser(action.payload),
            firstName: action.payload?.firstName?.trim(),
            lastName: action.payload?.lastName?.trim()
        }),
        setUserIdentities: (state, action) => {
            state.identities = action.payload;
        },
        updateUser: (state, action) => ({
            ...state,
            ...action.payload
        })
    },
    extraReducers: ({ addCase }) => {
        addCase(fetchUser.fulfilled, (state, { payload }) => ({
            ...state,
            ...payload
        }));
        addCase(updateNotificationsSettings.fulfilled, (state, { payload }) => {
            state.identities[payload.type] = payload;
        });
        addCase(getPasskeyIdentities.fulfilled, (state, { payload }) => {
            state.identities.passkey = normalizeUserIdentities(payload.identities).passkey;
        });
        addCase(createPasskeyIdentity.fulfilled, (state, { payload }) => {
            const currentPasskeyIdentities = state.identities.passkey ?? [];
            state.identities.passkey = currentPasskeyIdentities.concat(payload);
        });
        addCase(deletePasskeyIdentity.fulfilled, (state, { payload }) => {
            state.identities.passkey = state.identities.passkey?.filter((passkeyIdentity) => passkeyIdentity.value !== payload);
        });
        addCase(updatePasskeyIdentity.fulfilled, (state, { payload }) => {
            state.identities.passkey = state.identities.passkey?.map((passkeyIdentity) => {
                if (passkeyIdentity.value !== payload.value) {
                    return passkeyIdentity;
                }
                return payload;
            });
        });
    }
});
export const { setUser, setUserIdentities, updateUser } = userSlice.actions;
export const user = userSlice.reducer;
