import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Tabs } from '@copper/ui-kit';
import { sendTrackingEvent } from '@copper/utils';
import { Assets } from './Assets';
import { Topics } from './Topics';
import { ActiveAssetContext } from './context';
import s from './Guide.module.css';
export const StakingGuide = () => {
    const [activeTab, setActiveTab] = useState('topics');
    const [activeAsset, setActiveAsset] = useState('');
    const goToAsset = (asset) => {
        setActiveTab('assets');
        setActiveAsset(asset);
    };
    useEffect(() => {
        sendTrackingEvent('staking_guide_view');
    }, []);
    return (_jsxs("div", { className: s.container, children: [_jsx(Tabs, { view: "tool", items: [
                    {
                        title: 'Topics',
                        value: 'topics'
                    },
                    {
                        title: 'Assets',
                        value: 'assets'
                    }
                ], value: activeTab, onChange: (item) => setActiveTab(item.value) }), _jsx("div", { className: s.content, children: _jsxs("div", { className: s.container, children: [_jsx(ActiveAssetContext.Provider, { value: { goToAsset }, children: activeTab === 'topics' && _jsx(Topics, {}) }), activeTab === 'assets' && _jsx(Assets, { activeAsset: activeAsset })] }) })] }));
};
