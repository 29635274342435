import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { range } from 'lodash-es';
import { Skeleton } from '@copper/ui-kit';
import s from './Navigation.module.css';
const Navigation = ({ items, showSkeleton = false }) => {
    const location = useLocation();
    return (_jsx("nav", { className: s.navigation, children: showSkeleton
            ? range(0, 2).map((skeletonItemIndex) => (_jsxs("div", { className: s.navLink, children: [_jsx("div", { className: s.navLinkIcon, children: _jsx(Skeleton, { width: "24px", height: "24px", rounded: true }) }), ' ', _jsx(Skeleton, { height: "13px", width: "42px" })] }, `navLinkSkeleton-${skeletonItemIndex}`)))
            : items?.map(({ label, to, Icon, activeWhenUrlStartWith, rightSection }) => (_jsxs(NavLink, { to: to, "data-testid": `link-navigation-${label.toLowerCase()}`, className: ({ isActive }) => cn(s.navLink, {
                    [s.navLinkActive]: isActive ||
                        (activeWhenUrlStartWith && location.pathname.startsWith(activeWhenUrlStartWith))
                }), children: [Icon && _jsx(Icon, { className: s.navLinkIcon }), " ", label, ' ', rightSection && _jsx("span", { className: s.rightSectionContainer, children: rightSection })] }, label))) }));
};
export { Navigation };
