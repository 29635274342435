import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ChangePassword, EditButton, SettingsHeader, SettingsSection } from '@copper/components';
import { PasskeysList } from '@copper/components/Settings/PasskeysList';
import { TwoFaProvider } from '@copper/contexts/twoFa';
import { Button } from '@copper/ui-kit';
import { Link } from 'react-router-dom';
import s from './PasswordAndPasskeys.module.css';
export const PasswordAndPasskeys = ({ target }) => {
    return (_jsx(_Fragment, { children: _jsxs("div", { className: s.container, children: [_jsxs("div", { children: [_jsx(SettingsHeader, { title: "Password" }), _jsx(SettingsSection, { title: "Change password", description: "Choose a unique password to log in to your personal account", rightSection: _jsx(EditButton, { text: "Change" }), noTopRounding: true, noBottomRounding: true, children: _jsx(TwoFaProvider, { children: _jsx(ChangePassword, {}) }) })] }), _jsxs("div", { children: [_jsx(SettingsHeader, { title: "Two-step verification" }), _jsx(SettingsSection, { title: "Change verification method", description: "Use an authenticator app or a YubiKey security key ", rightSection: _jsx(Link, { to: "change-verification-method", children: _jsx(Button, { view: "secondary", text: "Change" }) }), noTopRounding: true })] }), _jsx(TwoFaProvider, { children: _jsx(PasskeysList, { target: target }) })] }) }));
};
