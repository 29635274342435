import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, formatDistanceToNow } from 'date-fns';
import { ConfirmationPopup, EmptyTableRow, PasskeyPopup, SettingsHeader } from '@copper/components';
import { useLayoutState } from '@copper/contexts/layout';
import { deletePasskeyIdentity, getPasskeyIdentities } from '@copper/entities/user/user-reducer';
import { selectUserPasskeys } from '@copper/entities/user/user-selector';
import { Button, ButtonIcon, Dropdown, IconMore, Skeleton, Table, Tbody, Td, Tr, useSnackBar } from '@copper/ui-kit';
import { getErrorData } from '@copper/utils';
import s from './PasskeysList.module.css';
const PASSKEY_DROPDOWN_OPTIONS = [
    { type: 'edit', title: 'Edit' },
    { type: 'delete', title: 'Delete', modifier: 'dangerous' }
];
export const PasskeysList = ({ target }) => {
    const [passkeyModalOpened, setPasskeyModalOpened] = useState(false);
    const { openSnackbar } = useSnackBar();
    const [passkeyForDelete, setPasskeyForDelete] = useState();
    const [passkeyForEdit, setPasskeyForEdit] = useState();
    const [passkeyDeletePending, setPasskeyDeletePending] = useState(false);
    const { loadingLayout } = useLayoutState();
    const [passkeysLoading, setPasskeysLoading] = useState(false);
    const dispatch = useDispatch();
    const passkeys = useSelector(selectUserPasskeys);
    const fetchPasskeys = async () => {
        try {
            setPasskeysLoading(true);
            await dispatch(getPasskeyIdentities()).unwrap();
        }
        catch (error) {
            openSnackbar(getErrorData(error));
        }
        finally {
            setPasskeysLoading(false);
        }
    };
    const handleDelete = async () => {
        if (!passkeyForDelete) {
            return;
        }
        try {
            setPasskeyDeletePending(true);
            await dispatch(deletePasskeyIdentity(passkeyForDelete?.value)).unwrap();
            setPasskeyForDelete(undefined);
        }
        catch (error) {
            openSnackbar(getErrorData(error));
            setPasskeyDeletePending(false);
        }
        finally {
            setPasskeyDeletePending(false);
        }
    };
    const handleDropdownChange = async (type, passkey) => {
        if (type === 'delete') {
            setPasskeyForDelete(passkey);
        }
        if (type === 'edit') {
            setPasskeyForEdit(passkey);
            setPasskeyModalOpened(true);
        }
    };
    useEffect(() => {
        Boolean(!loadingLayout && !passkeys.length) && fetchPasskeys();
    }, [loadingLayout, passkeys]);
    const showSkeleton = Boolean(loadingLayout || passkeysLoading);
    const onClosePasskeyModal = () => {
        setPasskeyModalOpened(false);
        setPasskeyForEdit(undefined);
    };
    return (_jsxs("div", { children: [_jsx(SettingsHeader, { title: "Passkeys", description: "Passkeys enable you to securely log in to your account using your fingerprint, face, screen lock, or hardware security key", customAction: _jsx(Button, { onClick: () => setPasskeyModalOpened(true), text: "Add new" }) }), _jsx(Table, { children: _jsxs(Tbody, { children: [showSkeleton && (_jsxs(Tr, { children: [_jsx(Td, { width: "33%", children: _jsx(Skeleton, { width: "100px", height: "18px", style: { margin: '8px 0' } }) }), _jsx(Td, { width: "120px", children: _jsx(Skeleton, { width: "80px", height: "18px" }) }), _jsx(Td, { children: _jsx(Skeleton, { width: "90px", height: "18px" }) }), _jsx(Td, { width: "28px", children: _jsx(Skeleton, { width: "28px", height: "28px" }) })] })), !showSkeleton &&
                            (passkeys.length ? (passkeys.map((passkey) => {
                                const { value, extra, createdAt } = passkey;
                                return (_jsxs(Tr, { children: [_jsx(Td, { width: "33%", children: extra?.passkey?.name }), _jsx(Td, { width: "120px", className: s.nonAccentValue, children: createdAt && format(parseInt(createdAt), 'd MMM yyyy') }), _jsx(Td, { className: s.nonAccentValue, children: passkey.lastUsedAt
                                                ? formatDistanceToNow(parseInt(passkey.lastUsedAt), { addSuffix: true })
                                                : 'Not used yet' }), _jsx(Td, { width: "28px", children: _jsx(Dropdown, { getKey: ({ type }) => type, getTitle: ({ title }) => title, getToggle: (isOpen) => (_jsx(ButtonIcon, { state: isOpen ? 'active' : 'default', children: _jsx(IconMore, {}) })), listWidth: 160, highlightSelectedItem: false, items: PASSKEY_DROPDOWN_OPTIONS, getModifier: ({ modifier }) => modifier, placement: "bottom", onChange: ({ type }) => handleDropdownChange(type, passkey) }) })] }, value));
                            })) : (_jsx(EmptyTableRow, { text: "No passkeys" })))] }) }), passkeyModalOpened && (_jsx(PasskeyPopup, { passkeyForEdit: passkeyForEdit, onClose: onClosePasskeyModal, target: target })), passkeyForDelete && (_jsx(ConfirmationPopup, { headerTitle: "Delete", title: "Delete passkey", subTitle: `Are you sure you want to delete ${passkeyForDelete?.extra?.passkey?.name} passkey?`, confirmText: "Delete", onConfirm: handleDelete, isSubmitting: passkeyDeletePending, onCancel: () => {
                    setPasskeyForDelete(undefined);
                } }))] }));
};
