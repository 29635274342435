import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { FormControl, FormLabel, Input } from '@copper/ui-kit';
import { yupResolver } from '@hookform/resolvers/yup';
import s from './Yubikey.module.css';
export const validationSchema = Yup.object()
    .shape({
    yubikey: Yup.string().required('Enter a YubiKey number.')
})
    .required();
const Yubikey = ({ errorMessage, onSuccess, formId = 'yubikey-form' }) => {
    const { formState, register, handleSubmit } = useForm({
        mode: 'onSubmit',
        resolver: yupResolver(validationSchema)
    });
    const { errors } = formState;
    const onSubmit = ({ yubikey }) => {
        onSuccess(yubikey);
    };
    return (_jsx("div", { className: s.container, children: _jsx("form", { id: formId, onSubmit: handleSubmit(onSubmit), children: _jsxs(FormControl, { errorMessage: errors?.yubikey?.message ?? errorMessage, children: [_jsx(FormLabel, { children: "Yubico OTP" }), _jsx(Input, { autoFocus: true, size: "xl", ...register('yubikey') })] }) }) }));
};
export { Yubikey };
