import * as lendingApi from '@copper/api/lending';
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { normalizeLendingLoans } from './lending-normalize';
const mergeLoan = (currentLoan, newLoan) => ({
    ...currentLoan,
    ...newLoan,
    _embedded: { ...currentLoan._embedded, ...newLoan._embedded }
});
const initialState = {
    loans: {},
    openedLoans: {},
    counterparties: {}
};
export const fetchLoan = createAsyncThunk('agencyLending/fetchLoan', async (params, thunkAPI) => {
    try {
        const response = await lendingApi.getLoanById(params);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const createLoan = createAsyncThunk('lending/createLoan', async ({ organizationId, data }, thunkAPI) => {
    try {
        const response = await lendingApi.createAgencyLoan({ organizationId, data });
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const cancelLoan = createAsyncThunk('lending/cancelLoan', async ({ organizationId, loanId }, thunkAPI) => {
    try {
        const response = await lendingApi.cancelPrincipalLoan(organizationId, loanId);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const approveLoan = createAsyncThunk('lending/approveLoan', async ({ organizationId, loanId }, thunkAPI) => {
    try {
        const response = await lendingApi.approveLoan(organizationId, loanId);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const rejectLoan = createAsyncThunk('lending/rejectLoan', async ({ organizationId, loanId }, thunkAPI) => {
    try {
        const response = await lendingApi.rejectLoan(organizationId, loanId);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const acceptLoan = createAsyncThunk('lending/acceptLoan', async ({ organizationId, loanId, data }, thunkAPI) => {
    try {
        const response = await lendingApi.acceptLoan(organizationId, loanId, data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const addCollateral = createAsyncThunk('lending/addCollateral', async ({ organizationId, loanId, data }, thunkAPI) => {
    try {
        const response = await lendingApi.addCollateral(organizationId, loanId, data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const repayLoan = createAsyncThunk('lending/repayLoan', async ({ organizationId, loanId, data }, thunkAPI) => {
    try {
        const response = await lendingApi.repayLoan(organizationId, loanId, data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const rebalanceLoan = createAsyncThunk('lending/rebalanceLoan', async ({ organizationId, loanId, data }, thunkAPI) => {
    try {
        const response = await lendingApi.rebalanceLoan(organizationId, loanId, data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const topUpLoan = createAsyncThunk('lending/topUpLoan', async ({ organizationId, loanId, data }, thunkAPI) => {
    try {
        const response = await lendingApi.topUpLoan(organizationId, loanId, data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const defaultLoan = createAsyncThunk('lending/defaultLoan', async ({ organizationId, loanId }, thunkAPI) => {
    try {
        const response = await lendingApi.defaultLoan(organizationId, loanId);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const cancelLoanAction = createAsyncThunk('lending/cancelLoanAction', async ({ organizationId, loanId, allocationType, data }, thunkAPI) => {
    try {
        if (['repay', 'repay-externally'].includes(allocationType)) {
            const response = await lendingApi.cancelLoanRepaymentRequest(organizationId, loanId, data);
            return response.data;
        }
        if (['top-up', 'top-up-externally'].includes(allocationType)) {
            const response = await lendingApi.cancelLoanTopUpAction(organizationId, loanId, data);
            return response.data;
        }
        if (['release-collateral', 'rebalance'].includes(allocationType)) {
            const response = await lendingApi.cancelLoanReleaseCollateralAction(organizationId, loanId, data);
            return response.data;
        }
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const acceptLoanAction = createAsyncThunk('lending/acceptLoanAction', async ({ organizationId, loanId, allocationType, data }, thunkAPI) => {
    try {
        if (['repay', 'repay-externally'].includes(allocationType)) {
            const response = await lendingApi.acceptLoanRepaymentRequest(organizationId, loanId, data);
            return response.data;
        }
        if (['top-up', 'top-up-externally'].includes(allocationType)) {
            const response = await lendingApi.acceptLoanTopUpAction(organizationId, loanId, data);
            return response.data;
        }
        if (['release-collateral', 'rebalance'].includes(allocationType)) {
            const response = await lendingApi.acceptLoanReleaseCollateralAction(organizationId, loanId, data);
            return response.data;
        }
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const confirmExternalDisbursement = createAsyncThunk('lending/confirmExternalDisbursement', async ({ organizationId, loanId, data }, thunkAPI) => {
    try {
        const response = await lendingApi.confirmExternalDisbursement(organizationId, loanId, data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const confirmExternalTopup = createAsyncThunk('lending/confirmExternalTopup', async ({ organizationId, loanId, data }, thunkAPI) => {
    try {
        const response = await lendingApi.confirmExternalTopup(organizationId, loanId, data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const confirmExternalRepayment = createAsyncThunk('lending/confirmExternalRepayment', async ({ organizationId, loanId, data }, thunkAPI) => {
    try {
        const response = await lendingApi.confirmExternalRepayment(organizationId, loanId, data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const rerateLoan = createAsyncThunk('lending/rerateLoan', async ({ organizationId, loanId, data }, thunkAPI) => {
    try {
        const response = await lendingApi.rerateLoan(organizationId, loanId, data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const acceptLoanRerate = createAsyncThunk('lending/acceptLoanRerate', async ({ organizationId, loanId, data }, thunkAPI) => {
    try {
        const response = await lendingApi.acceptLoanRerate(organizationId, loanId, data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const cancelLoanRerate = createAsyncThunk('lending/cancelLoanRerate', async ({ organizationId, loanId, data }, thunkAPI) => {
    try {
        const response = await lendingApi.cancelLoanRerate(organizationId, loanId, data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const extendTermLoan = createAsyncThunk('lending/extendTermLoan', async ({ organizationId, loanId, data }, thunkAPI) => {
    try {
        const response = await lendingApi.extendTermLoan(organizationId, loanId, data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const acceptLoanTermExtension = createAsyncThunk('lending/acceptLoanTermExtension', async ({ organizationId, loanId, data }, thunkAPI) => {
    try {
        const response = await lendingApi.acceptLoanTermExtension(organizationId, loanId, data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const cancelLoanTermExtension = createAsyncThunk('lending/cancelLoanTermExtension', async ({ organizationId, loanId, data }, thunkAPI) => {
    try {
        const response = await lendingApi.cancelLoanTermExtension(organizationId, loanId, data);
        return response.data;
    }
    catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const lendingSlice = createSlice({
    name: 'lending',
    initialState,
    reducers: {
        setLoans(state, { payload }) {
            const { loans, counterparties } = normalizeLendingLoans(payload);
            state.loans = loans;
            state.counterparties = counterparties;
        },
        updateLoans(state, { payload }) {
            const { loans: updatedLoans } = normalizeLendingLoans(payload);
            Object.values(updatedLoans).forEach((updatedLoan) => {
                if (updatedLoan) {
                    const existingLoan = state.loans[updatedLoan.loanId];
                    if (existingLoan) {
                        state.loans[updatedLoan.loanId] = existingLoan
                            ? mergeLoan(existingLoan, updatedLoan)
                            : updatedLoan;
                    }
                    const existingOpenedLoan = state.openedLoans[updatedLoan.loanId];
                    if (existingOpenedLoan) {
                        state.openedLoans = {
                            ...state.openedLoans,
                            [updatedLoan.loanId]: mergeLoan(existingOpenedLoan, updatedLoan)
                        };
                    }
                }
            });
        }
    },
    extraReducers: ({ addCase, addMatcher }) => {
        addCase(fetchLoan.fulfilled, (state, { payload }) => {
            state.openedLoans = { ...state.openedLoans, [payload.loanId]: payload };
        });
        addMatcher(isAnyOf(acceptLoan.fulfilled, rejectLoan.fulfilled, approveLoan.fulfilled, createLoan.fulfilled, cancelLoan.fulfilled, addCollateral.fulfilled, repayLoan.fulfilled, rebalanceLoan.fulfilled, topUpLoan.fulfilled, defaultLoan.fulfilled, cancelLoanAction.fulfilled, confirmExternalDisbursement.fulfilled), (state, { payload: loan }) => {
            const existingLoan = state.loans[loan.loanId];
            if (state.openedLoans[loan.loanId]) {
                const currentLoan = state.openedLoans[loan.loanId];
                state.openedLoans = {
                    ...state.openedLoans,
                    [loan.loanId]: mergeLoan(currentLoan, loan)
                };
            }
            if (existingLoan) {
                state.loans[loan.loanId] = mergeLoan(existingLoan, loan);
            }
        });
    }
});
const { reducer, actions } = lendingSlice;
export const { updateLoans, setLoans } = actions;
export const lending = reducer;
