import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { SettingsSectionControls } from '@copper/components/Settings/SettingsSection/Controls';
import { useTwoFaContext } from '@copper/contexts/twoFa';
import { changePassword } from '@copper/entities/user/user-reducer';
import { selectUserEmail, selectUserId } from '@copper/entities/user/user-selector';
import { Alert, FormControl, FormLabel, PasswordInput, useCollapseContext, useSnackBar } from '@copper/ui-kit';
import { yupResolver } from '@hookform/resolvers/yup';
import { sha256 } from '@noble/hashes/sha256';
import { bytesToHex } from '@noble/hashes/utils';
import { updateToken } from '@copper/entities/auth/auth-reducer';
import { saveAuthData, setAuthorizationHeader } from '@copper/utils';
import { defaultValues, validationSchema } from './helpers';
import s from './ChangePassword.module.css';
const formId = 'change-password-form';
const ChangePassword = ({ target = 'platform' }) => {
    const email = useSelector(selectUserEmail);
    const userId = useSelector(selectUserId);
    const { openSnackbar } = useSnackBar();
    const { requestTwoFa, resolveTwoFa, twoFaErrorCatcher } = useTwoFaContext();
    const dispatch = useDispatch();
    const { handleToggleCollapse } = useCollapseContext();
    const { control, formState: { errors }, handleSubmit, reset } = useForm({
        mode: 'onChange',
        resolver: yupResolver(validationSchema)
    });
    const resetForm = () => {
        reset(defaultValues);
        handleToggleCollapse();
    };
    const onSave = handleSubmit(async (values) => {
        try {
            const twoFaCode = await requestTwoFa('change-password');
            const data = {
                userId,
                twoFaCode,
                oldPassword: window.btoa(`${email}:${bytesToHex(sha256(values?.oldPassword + email))}`),
                password: bytesToHex(sha256(values?.newPassword + email)),
                target
            };
            const token = await dispatch(changePassword(data)).unwrap();
            if (token) {
                await dispatch(updateToken({
                    token: token.token,
                    expiredAt: token.expiredAt
                }));
                setAuthorizationHeader(token.token);
                saveAuthData({
                    token: token.expiredAt,
                    expiredAt: token.expiredAt
                });
            }
            resolveTwoFa();
            openSnackbar({ message: 'Password Updated', type: 'success' });
            resetForm();
        }
        catch (error) {
            twoFaErrorCatcher(error, onSave);
        }
    });
    const renderPasswordInput = ({ name, label }) => (_jsxs(FormControl, { errorMessage: errors[name]?.message, children: [_jsx(FormLabel, { children: label }), _jsx(Controller, { control: control, name: name, render: ({ field }) => (_jsx(PasswordInput, { value: field.value, size: "l", onChange: (e) => {
                        field.onChange(e.target.value);
                    } })) })] }, name));
    const PASSWORD_INPUTS = [
        { name: 'oldPassword', label: 'current password' },
        { name: 'newPassword', label: 'new password' },
        { name: 'confirmNewPassword', label: 'confirm new password' }
    ];
    return (_jsxs(_Fragment, { children: [_jsx("form", { id: formId, onSubmit: onSave, className: s.container, children: _jsxs("div", { className: s.passwords, children: [PASSWORD_INPUTS.map((input) => renderPasswordInput(input)), _jsx(Alert, { type: "success", message: "Protect your account with a secure password. Use 8 or more characters with a mix of uppercase and lowercase letters, numbers and special characters." })] }) }), _jsx(SettingsSectionControls, { onCancel: resetForm, formId: formId })] }));
};
export { ChangePassword };
