import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import BigNumber from 'bignumber.js';
import { AssetIcon, InfoAccount, InfoRow } from '@copper/components';
import { CounterpartyNetworkCard } from '@copper/components/Counterparty/CounterpartyNetworkCard';
import { SwitchStateRow } from '@copper/components/Forms/SwitchStateRow';
import { getDeliveryTypeOptions } from '@copper/components/OrderPopup/orders/LiteSettlement/FlowStepsList/helpers';
import { NetworkInfoRow } from '@copper/components/OrderPopup/ui/NetworkInfoRow';
import { useCurrenciesInfo } from '@copper/hooks/useCurrenciesInfo';
import { ButtonLink, Checkbox, useSnackBar } from '@copper/ui-kit';
import { formatCurrency, getErrorData } from '@copper/utils';
import { selectNetworks } from '@copper/entities/networks/networks-selectors';
import { useSelector } from 'react-redux';
import s from './Summary.module.css';
const Summary = ({ isSubmitting, data, onNext, disclaimer, fopDisclaimer }) => {
    const { getDecimals } = useCurrenciesInfo();
    const networks = useSelector(selectNetworks);
    const [termsAgreed, setTermsAgreed] = useState(false);
    const [fopTermsAgreed, setFopTermsAgreed] = useState(false);
    const { openSnackbar } = useSnackBar();
    const { baseCurrency, mainCurrency, quoteCurrency, quoteMainCurrency, portfolio, amount, priceOrQuoteAmount, counterparty, description, deliveryType, usePriceLimit } = data;
    const { handleAcceptDisclaimer, handleDisclaimerDownload, showDisclaimer, disclaimer: disclaimerData } = disclaimer;
    const { handleAcceptDisclaimer: handleAcceptFopDisclaimer, handleDisclaimerDownload: handleFopDisclaimerDownload, showDisclaimer: showFopDisclaimer, disclaimer: fopDisclaimerData } = fopDisclaimer;
    const handleSendClick = async () => {
        if (!disclaimerData?.accepted || !fopDisclaimerData?.accepted) {
            if (!disclaimerData?.accepted && !termsAgreed && counterparty?.counterpartyType === 'lite') {
                openSnackbar({
                    type: 'warning',
                    message: 'You need to accept Copper Lite Terms and Conditions'
                });
                return;
            }
            if (!fopDisclaimerData?.accepted && !fopTermsAgreed && deliveryType === 'free-of-payment') {
                openSnackbar({
                    type: 'warning',
                    message: 'You need to accept the legal disclaimer about FoP'
                });
                return;
            }
            try {
                if (deliveryType === 'free-of-payment') {
                    await handleAcceptFopDisclaimer();
                }
                await handleAcceptDisclaimer();
            }
            catch (err) {
                openSnackbar(getErrorData(err));
                setTermsAgreed(false);
                setFopTermsAgreed(false);
                return;
            }
        }
        onNext();
    };
    const priceToShow = usePriceLimit
        ? priceOrQuoteAmount
        : new BigNumber(priceOrQuoteAmount || '0').dividedBy(amount || '0').toFixed();
    const receivingAmount = usePriceLimit
        ? new BigNumber(amount || '0').multipliedBy(priceOrQuoteAmount || '0').toFixed()
        : priceOrQuoteAmount || '0';
    const deliveryTypeLabel = getDeliveryTypeOptions().find((type) => type.value === deliveryType)?.label;
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: s.container, children: [_jsx("h2", { className: s.title, children: "Summary" }), _jsx(InfoRow, { title: "Account", titleColor: "ghost", children: _jsx(InfoAccount, { account: portfolio, address: portfolio.accountId }) }), _jsx(InfoRow, { title: "Send", titleColor: "ghost", children: _jsxs("div", { className: s.asset, children: [formatCurrency(amount || '0', {
                                    decimalPlaces: getDecimals(baseCurrency.currency, networks[mainCurrency ?? '']?.nativeCurrency, { useDictionaryDecimal: true })
                                }), ' ', _jsx("span", { className: s.ticker, children: baseCurrency.currency }), _jsx(AssetIcon, { size: "s", currency: baseCurrency.currency })] }) }), _jsx(InfoRow, { title: "Receive", titleColor: "ghost", children: _jsxs("div", { className: s.asset, children: [formatCurrency(receivingAmount, {
                                    decimalPlaces: getDecimals(quoteCurrency.currency, networks[quoteMainCurrency ?? '']?.nativeCurrency, { useDictionaryDecimal: true })
                                }), _jsx("span", { className: s.ticker, children: quoteCurrency.currency }), _jsx(AssetIcon, { size: "s", currency: quoteCurrency.currency })] }) }), _jsx(NetworkInfoRow, { titleColor: "ghost", baseCurrency: baseCurrency.currency, mainCurrency: mainCurrency, title: "Payment network" }), _jsx(NetworkInfoRow, { titleColor: "ghost", baseCurrency: quoteCurrency.currency, mainCurrency: quoteMainCurrency, title: "Receiving network" }), _jsx(InfoRow, { title: "Price", titleColor: "ghost", children: _jsxs("div", { className: s.asset, children: [formatCurrency(priceToShow, {
                                    decimalPlaces: getDecimals(quoteCurrency.currency, networks[quoteMainCurrency ?? '']?.nativeCurrency, { useDictionaryDecimal: true })
                                }), ' ', _jsxs("span", { className: s.ticker, children: [quoteCurrency.currency, " per ", baseCurrency.currency] }), _jsxs("div", { children: [_jsx("div", { className: s.icon, children: _jsx(AssetIcon, { currency: baseCurrency.currency, size: "s" }) }), _jsx("div", { className: s.secondIcon, children: _jsx(AssetIcon, { currency: quoteCurrency.currency, size: "s" }) })] })] }) }), deliveryTypeLabel && (_jsx(InfoRow, { title: "Settlement method", titleColor: "ghost", children: _jsx("div", { children: deliveryTypeLabel }) })), counterparty && (_jsx(InfoRow, { title: "Counterparty", contentUnderTitle: true, titleColor: "ghost", children: _jsx(CounterpartyNetworkCard, { counterparty: counterparty, view: "tool", form: "preview" }) })), description && (_jsx(InfoRow, { title: "Optional note", titleColor: "ghost", children: description })), counterparty?.counterpartyType === 'lite' && showDisclaimer && (_jsx("div", { className: s.termsCheckbox, children: _jsx(Checkbox, { name: "liteTerms", checked: termsAgreed, onChange: setTermsAgreed, label: _jsxs("div", { className: s.termsLabel, children: ["I agree to the", ' ', _jsx(ButtonLink, { onClick: handleDisclaimerDownload, text: "Copper Lite Terms and Conditions." }), "*"] }) }) })), deliveryType === 'free-of-payment' && showFopDisclaimer && (_jsx("div", { className: s.termsCheckbox, children: _jsx(Checkbox, { name: "fopTerms", checked: fopTermsAgreed, onChange: setFopTermsAgreed, label: _jsxs("div", { className: s.termsLabel, children: ["Please accept the legal disclaimer prior to proceeding with a", ' ', _jsx(ButtonLink, { onClick: handleFopDisclaimerDownload, text: "Free of Payment settlement" }), "."] }) }) }))] }), _jsx(SwitchStateRow, { loading: isSubmitting, testId: "settle-button-send", onClick: handleSendClick, text: "Send" })] }));
};
export { Summary };
