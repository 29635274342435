import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import { getNumberOfChanges } from '@copper/components/AddressBook/Popups/AddressDetails/helpers';
import { selectPortfolioList } from '@copper/entities/portfolios/portfolios-selector';
import { getCurrencyDecimal, isCurrencyFiat } from '@copper/helpers';
import { isExternalPortfolio, isTradingPortfolio } from '@copper/helpers/portfolios';
import { formatCurrency, formatFiat } from '@copper/utils';
import { NOT_ACTIVATED } from './constants';
export const emptyData = {
    external: 'No external addresses yet',
    exchange: 'No exchange accounts yet',
    internal: 'No accounts yet',
    nonCustodial: 'No wallets yet',
    counterparties: 'No organisations yet',
    currencyBalance: 'No available balance in this currency'
};
export const emptySearch = {
    external: 'No addresses match this name',
    exchange: 'No exchanges match this name',
    internal: 'No accounts match this name',
    nonCustodial: 'No wallets  match this name',
    counterparties: 'No organisations match this name'
};
export const generateTabs = ({ hasExternal = false, hasCounterparties = false, hasNonCustodialWallets = false }) => {
    const tabs = [
        {
            title: 'Copper',
            value: 'internal'
        },
        {
            title: 'Exchange',
            value: 'exchange'
        }
    ];
    if (hasExternal) {
        tabs.push({
            title: 'External',
            value: 'external'
        });
    }
    if (hasNonCustodialWallets) {
        tabs.push({
            title: 'Non-custodial',
            value: 'nonCustodial'
        });
    }
    if (hasCounterparties) {
        tabs.push({
            title: 'Network',
            value: 'counterparties'
        });
    }
    return tabs;
};
export const calculateAvailableBalance = (args) => {
    const { walletsByPortfolioId, currenciesEntities, portfolio, currencyId, mainCurrencyId, showTransferableAmount } = args;
    const portfolioWallets = walletsByPortfolioId[portfolio.portfolioId] ?? [];
    const wallets = portfolioWallets.filter((wallet) => {
        if (mainCurrencyId &&
            !isExternalPortfolio(portfolio.portfolioType) &&
            !isCurrencyFiat(currenciesEntities, currencyId)) {
            return wallet.currency === currencyId && wallet.mainCurrency === mainCurrencyId;
        }
        return wallet.currency === currencyId;
    });
    if (wallets.length === 0) {
        return;
    }
    if (showTransferableAmount && wallets.length === 1) {
        const transferableAmount = wallets[0]?._embedded?.transferableAmount;
        if (wallets[0]?.portfolioType === 'external' && transferableAmount) {
            return transferableAmount;
        }
    }
    return wallets.reduce((acc, wallet) => new BigNumber(acc).plus(wallet.available).toFixed(), '0');
};
export const getAvailableBalance = ({ walletsByPortfolioId, currenciesEntities, tokens, portfolio, currencyId, mainCurrencyId, showTransferableAmount }) => {
    if (!currencyId) {
        return formatFiat(portfolio.available);
    }
    const available = calculateAvailableBalance({
        walletsByPortfolioId,
        currenciesEntities,
        portfolio,
        currencyId,
        mainCurrencyId,
        showTransferableAmount
    });
    if (available === undefined) {
        return ['trading', 'oxygen-vault', 'csl-pledge'].includes(portfolio.portfolioType)
            ? `0.00 ${currencyId}`
            : NOT_ACTIVATED;
    }
    return `${formatCurrency(available, {
        decimalPlaces: getCurrencyDecimal({ tokens, currencyId, mainCurrencyId })
    })} ${currencyId}`;
};
export const generateAddressBook = ({ cryptoAddresses, mainCurrencies, currencyId, originPortfolioId, whiteLockEnabled, excludeSmartContracts }) => {
    const filters = {
        whiteLockEnabled: (address) => {
            if (!whiteLockEnabled) {
                return true;
            }
            if (address.pendingApproval && typeof address.extra?.previousIsWhitelist === 'boolean')
                return address.extra.previousIsWhitelist;
            return address.isWhitelist;
        },
        currency: (address) => {
            if (!currencyId) {
                return true;
            }
            return (address.currency === currencyId ||
                (address.acceptTokens && mainCurrencies?.includes(address?.mainCurrency ?? '')));
        },
        portfolio: (address) => {
            const { portfolioIds = [] } = address;
            if (address.pendingApproval && address.extra?.previousPortfolioIds) {
                if (originPortfolioId && address.extra?.previousPortfolioIds.includes(originPortfolioId))
                    return true;
            }
            if (!originPortfolioId || !portfolioIds.length) {
                return true;
            }
            return portfolioIds.includes(originPortfolioId);
        },
        excludeSmartContracts: (address) => {
            if (!excludeSmartContracts) {
                return true;
            }
            return address.addressType !== 'smart-contract';
        }
    };
    return cryptoAddresses
        .filter((address) => Object.keys(filters).every((filterKey) => {
        const changesLength = getNumberOfChanges(address);
        const isNewAddress = address.pendingApproval && changesLength === 0;
        return !isNewAddress && filters[filterKey](address);
    }))
        .map((address) => {
        if (address.pendingApproval) {
            return {
                ...address,
                isWhitelist: typeof address.extra?.previousIsWhitelist === 'boolean'
                    ? address.extra.previousIsWhitelist
                    : address.isWhitelist,
                name: address.extra?.previousName || address.name
            };
        }
        return address;
    })
        .sort((a, b) => a.name.localeCompare(b.name));
};
export const checkIsAvailable = ({ walletsByPortfolioId, currenciesEntities, portfolio, currencyId, mainCurrencyId, showDestination }) => {
    let available;
    if (!currencyId) {
        available = portfolio.available;
    }
    else {
        available = calculateAvailableBalance({
            walletsByPortfolioId,
            currenciesEntities,
            portfolio,
            currencyId,
            mainCurrencyId,
            showTransferableAmount: true
        });
    }
    if (!available)
        return false;
    if (showDestination) {
        return true;
    }
    return new BigNumber(available).isGreaterThan(0);
};
export const filterNestedUnavailable = ({ portfolios, walletsByPortfolioId, currenciesEntities, currencyId, mainCurrencyId, showDestination }) => portfolios.reduce((acc, portfolio) => {
    const newPortfolio = { ...portfolio };
    const portfolioAvailable = checkIsAvailable({
        walletsByPortfolioId,
        currenciesEntities,
        portfolio: portfolio.item,
        currencyId,
        mainCurrencyId,
        showDestination
    });
    const needExcludePortfolio = !portfolioAvailable && !portfolio.next && !portfolio.item.isShadow;
    if ((needExcludePortfolio && !showDestination) ||
        (needExcludePortfolio && showDestination && !isTradingPortfolio(portfolio.item.portfolioType))) {
        return acc;
    }
    if (portfolio.next) {
        const filteredNext = filterNestedUnavailable({
            portfolios: portfolio.next,
            walletsByPortfolioId,
            currenciesEntities,
            currencyId,
            mainCurrencyId,
            showDestination
        });
        if (!portfolioAvailable && !filteredNext.length && !portfolio.item.isShadow) {
            return acc;
        }
        newPortfolio.next = filteredNext.length ? filteredNext : null;
    }
    acc.push(newPortfolio);
    return acc;
}, []);
export const filterNestedPortfoliosByIds = (portfolios, portfolioIds) => {
    if (!portfolioIds?.length) {
        return portfolios;
    }
    return portfolios.reduce((acc, node) => {
        const newNode = { ...node };
        const needExcludeNode = portfolioIds?.includes(node.item.portfolioId);
        if (needExcludeNode && !node.next) {
            return acc;
        }
        if (node.next) {
            const filteredNext = filterNestedPortfoliosByIds(node.next, portfolioIds);
            if (needExcludeNode && !filteredNext.length) {
                return acc;
            }
            newNode.next = filteredNext.length ? filteredNext : null;
        }
        acc.push(newNode);
        return acc;
    }, []);
};
export const useTransferPortfolio = () => {
    const portfolioList = useSelector(selectPortfolioList);
    return (portfolioId) => {
        if (!portfolioId) {
            return;
        }
        return portfolioList.find((item) => item.portfolioId === portfolioId);
    };
};
