import { isLoanWithCollateralType } from '@copper/components/Loans/helpers';
import * as Yup from 'yup';
export const validationSchema = Yup.object().shape({
    portfolio: Yup.object()
        .test('portfolio', (value, { parent, createError }) => {
        const { externalDisbursal } = parent;
        if (externalDisbursal) {
            return true;
        }
        else {
            return !value
                ? createError({
                    message: `Select a portfolio`
                })
                : true;
        }
    }),
    loanType: Yup.string().required('Select an loan type.'),
    amount: Yup.string().required('Enter a loan amount.'),
    availableBalance: Yup.string(),
    externalDisbursal: Yup.boolean(),
    counterparty: Yup.object().required('Select a borrower.'),
    currency: Yup.object().required('Select an asset.'),
    openTerm: Yup.boolean(),
    maturityDate: Yup.string().when('openTerm', {
        is: (openTerm) => !openTerm,
        then: (schema) => schema.required('Select a loan term.')
    }),
    collateralAmount: Yup.string().when('loanType', {
        is: (loanType) => isLoanWithCollateralType(loanType),
        then: (schema) => schema.required('Enter a collateral amount.')
    }),
    collateralMainCurrency: Yup.string().when('loanType', {
        is: (loanType) => isLoanWithCollateralType(loanType),
        then: (schema) => schema.required('Select a network.')
    }),
    interestRateBps: Yup.string().required('Enter an interest rate.'),
    rebalancePercent: Yup.string().when('loanType', {
        is: (loanType) => isLoanWithCollateralType(loanType),
        then: (schema) => schema.required('Enter a rebalance rate.')
    }),
    marginCallPercent: Yup.string().when('loanType', {
        is: (loanType) => isLoanWithCollateralType(loanType),
        then: (schema) => schema.required('Enter a margin call rate.')
    }),
    defaultPercent: Yup.string().when('loanType', {
        is: (loanType) => isLoanWithCollateralType(loanType),
        then: (schema) => schema.required('Enter a default rate.')
    }),
    deferredDisbursement: Yup.boolean(),
    startDate: Yup.string().when('deferredDisbursement', {
        is: (deferredDisbursement) => deferredDisbursement,
        then: (schema) => schema.required('Enter a disbursement date.')
    })
});
