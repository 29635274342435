import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { Skeleton } from '@copper/ui-kit';
import s from './SettingMenuItem.module.css';
const SettingMenuItem = ({ title, icon, link, asSkeleton = false, testId }) => {
    if (asSkeleton) {
        return (_jsxs("div", { className: s.item, children: [_jsx("span", { className: s.icon, children: _jsx(Skeleton, { width: 24, height: 24, variant: "circle" }) }), _jsx(Skeleton, { width: 8 * title.length, height: 20 })] }));
    }
    return (_jsxs(NavLink, { to: link, className: ({ isActive }) => cn(s.item, {
            [s.active]: isActive
        }), "data-testid": testId, children: [_jsx("span", { className: s.icon, children: icon }), title] }));
};
export { SettingMenuItem };
