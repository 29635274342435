import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonIcon, Dropdown, IconMore } from '@copper/ui-kit';
import cn from 'classnames';
import React from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import s from './Menu.module.css';
export const Menu = ({ menuItems = [], getRightSection, testId, wrapAt }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    if (!menuItems.length) {
        return null;
    }
    const itemsToDisplay = wrapAt ? menuItems.slice(0, wrapAt) : menuItems;
    const hiddenItems = wrapAt ? menuItems.slice(wrapAt, menuItems.length) : [];
    const handleDropdownChange = (item) => {
        navigate(item.path);
    };
    return (_jsxs("div", { className: s.menu, "data-testid": testId, children: [itemsToDisplay.map((item) => {
                const rightSection = getRightSection && getRightSection(item);
                return (_jsx(NavLink, { to: item.path, className: ({ isActive }) => cn(s.item, {
                        [s.active]: isActive
                    }), children: _jsxs("div", { className: s.itemContainer, "data-testid": `${item.title.toLowerCase().split(' ').join('-')}-tab`, children: [item.title, rightSection && _jsx("div", { children: rightSection })] }) }, item.path));
            }), !!hiddenItems.length && (_jsx(Dropdown, { highlightSelectedItem: false, placement: "bottom", listWidth: 200, getTitle: ({ title }) => title, getKey: ({ path }) => path, onChange: handleDropdownChange, items: hiddenItems, selected: menuItems.find((item) => pathname.includes(item.path)), getToggle: (isOpen) => (_jsxs("div", { className: s.moreButton, children: ["More", _jsx(ButtonIcon, { state: isOpen ? 'active' : 'default', children: _jsx(IconMore, { type: "horizontal" }) })] })) }))] }));
};
