import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatInTimeZone } from 'date-fns-tz';
import { InvoiceListPopup, InvoicePaymentPopup } from '@copper/components';
import { fetchInvoicesByOrganizationId } from '@copper/entities/invoices/invoices-reducer';
import { selectUnpaidInvoicesByOrganisation, selectUnpaidInvoicesTotalBalance } from '@copper/entities/invoices/invoices-selector';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { Button } from '@copper/ui-kit';
import { IconInvoices } from '@copper/ui-kit/icons/Invoices';
import { formatFiat } from '@copper/utils';
import { setCssProperty } from '@copper/utils/styles';
import s from './Invoices.module.css';
export const INVOICE_BANNER_HEIGHT = '48px';
const InvoicesBanner = () => {
    const dispatch = useDispatch();
    const { organizationId } = useSelector(selectCurrentOrganization);
    const unpaidInvoices = useSelector(selectUnpaidInvoicesByOrganisation);
    const unpaidTotalBalance = useSelector(selectUnpaidInvoicesTotalBalance);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    useEffect(() => {
        setCssProperty('--invoice-banner-height', unpaidTotalBalance > 0 ? INVOICE_BANNER_HEIGHT : '0px');
    }, [unpaidTotalBalance]);
    if (unpaidTotalBalance === 0) {
        return null;
    }
    const getBannerText = () => {
        if (unpaidInvoices.length === 1) {
            return `Your ${formatInTimeZone(Number(unpaidInvoices[0].invoice.period), 'UTC', 'MMM yyyy')} invoice is due.`;
        }
        return `You have ${unpaidInvoices.length} unpaid invoices.`;
    };
    const handleInvoicePaymentSuccess = async () => {
        await dispatch(fetchInvoicesByOrganizationId(organizationId)).unwrap();
    };
    return (_jsxs("div", { className: s.headerBanner, children: [_jsx(IconInvoices, {}), _jsxs("div", { className: s.text, "data-testid": "text-invoice-banner", children: [getBannerText(), " Total to pay ", formatFiat(unpaidTotalBalance)] }), _jsx(Button, { size: "s", text: "View details", view: "primary", onClick: () => setIsPopupVisible(true), testId: "button-invoice-banner" }), isPopupVisible && (_jsx(InvoiceListPopup, { onClose: () => setIsPopupVisible(false), onSelect: (invoice) => {
                    setIsPopupVisible(false);
                    setSelectedInvoice(invoice);
                } })), selectedInvoice && (_jsx(InvoicePaymentPopup, { invoice: selectedInvoice, onBack: () => {
                    setIsPopupVisible(true);
                    setSelectedInvoice(null);
                }, onClose: () => setSelectedInvoice(null), onSuccess: handleInvoicePaymentSuccess }))] }));
};
export { InvoicesBanner };
