import { request } from '@copper/utils';
export const get = (rateFor) => request.get(`/dictionaries?rateFor=${rateFor}`);
export const getFeeTypes = () => request.get('/dictionaries/fee-types');
export const getNetworks = () => request.get('/dictionaries/networks');
export const getNetwork = ({ mainCurrency }) => request.get(`/dictionaries/networks/${mainCurrency}`);
export const getTokens = () => request.get('/dictionaries/tokens');
export const getToken = ({ currency }) => request.get(`/dictionaries/tokens/${currency}`);
export const getNetworkTokens = () => request.get('/dictionaries/network-tokens');
export const getNetworkTokensByQuery = (params) => request.get('/dictionaries/network-tokens', { params });
export const createTokenModification = (data) => request.post('/dictionaries/tokens-modifications', data);
export const createNetworkTokenModification = (data) => request.post('/dictionaries/network-tokens-modifications', data);
export const getDepositBankAccounts = (params) => request.get('/dictionaries/deposit-bank-accounts', { params });
export const createDepositBankAccount = (data) => request.post('/dictionaries/deposit-bank-accounts', data);
export const updateDepositBankAccount = (bankAccountId, data) => request.patch(`/dictionaries/deposit-bank-accounts/${bankAccountId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.update+json'
    }
});
export const deactivateDepositBankAccount = (bankAccountId) => request.patch(`/dictionaries/deposit-bank-accounts/${bankAccountId}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.deactivate+json'
    }
});
