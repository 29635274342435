import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { AppDownloadButton } from '@copper/components/AppDownloadButton';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { createBoundedToken } from '@copper/entities/portfolios/portfolios-actions';
import { APP_OPEN_TIMEOUT, generateCUDeepLinkForShard, getPlatformType } from '@copper/helpers/newCU';
import { Button, Loader, PopupContent, useSnackBar } from '@copper/ui-kit';
import { getErrorData } from '@copper/utils';
import { createBoundedTokenForOrganization } from '@copper/entities/organizations/organizations-actions';
import s from './GenerateShardLink.module.css';
export const GenerateShardLink = ({ portfolioId, name }) => {
    const { openSnackbar } = useSnackBar();
    const { organizationId } = useSelector(selectCurrentOrganization);
    const [isLoadingApp, setIsLoadingApp] = useState(false);
    const handleOpenNewCUClick = async () => {
        setIsLoadingApp(true);
        try {
            const [accountToken, organizationToken] = await Promise.all([
                createBoundedToken(portfolioId),
                createBoundedTokenForOrganization(organizationId)
            ]);
            window.open(generateCUDeepLinkForShard({
                token: accountToken.token,
                organizationToken: organizationToken.token,
                organizationId,
                platform: getPlatformType(window.location.hostname),
                portfolioId,
                portfolioName: name,
                correlationId: accountToken.correlationId
            }), '_self');
            let isBlurred = false;
            let timerId;
            window.onblur = () => {
                setIsLoadingApp(false);
                if (timerId) {
                    isBlurred = true;
                }
            };
            timerId = setTimeout(() => {
                if (!isBlurred) {
                    openSnackbar({
                        type: 'error',
                        autoClose: false,
                        message: 'You need the new version of the Copper Unlimited app to generate shard. Please download Copper Unlimited New and try again.',
                        action: _jsx(AppDownloadButton, { size: "s", type: "newCustody" })
                    });
                    setIsLoadingApp(false);
                }
            }, APP_OPEN_TIMEOUT);
        }
        catch (err) {
            openSnackbar(getErrorData(err));
            setIsLoadingApp(false);
        }
    };
    return (_jsx(PopupContent, { children: _jsxs("div", { className: s.container, children: [_jsx(Loader, { view: "secondary" }), _jsx("h3", { className: s.title, children: "Continue with Copper Unlimited" }), _jsx("div", { className: s.description, children: "You\u2019ll be redirected to the Copper Unlimited app to generate your shard. Once completed, you\u2019ll automatically return here." }), _jsx(Button, { fullWidth: true, size: "xl", loading: isLoadingApp, onClick: handleOpenNewCUClick, text: "Open Copper Unlimited" })] }) }));
};
